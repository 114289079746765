.project{
    padding: 0 col(4,24);
    margin-bottom: s(90);
    display: flex;

    &__main{
        width: col(10, 16);
    }

    &__intro{
        width: col(9, 10);
        @extend %intro;
    }

    &__content{
        @extend %body;
    }
    
    &__invoiceButton{
        margin-top: s(30);
    }

    &__featureList{
        margin-left: col(1,16);
        width: col(5,16);
    }

    &__featureItem{
        & + &{
            border-top: 1px solid rgba(0,0,0,0.1);
        }

        &:first-child{
            .project__featureTerm,
            .project__featureData{
                padding-top: 0;
            }
        }
    }

    &__featureTerm,
    &__featureData{
        text-transform: uppercase;
        font-size: s(10);
        list-style: ps_ls(50);
        font-weight: 600;
        padding: s(15) 0;
    }

    &__featureTerm{
        text-align: left;
        color: #7a7575;

    }

    &__featureData{
        color: color(lightRed);
    }

    @media screen and (max-width: em(1024,16)){
        padding: 0 col(2,24);
        margin-bottom: s(75);

    }

    @media screen and (max-width: em(600,16)){
        padding: 0 col(2,24);
        flex-direction: column;
        margin-bottom: s(50);


        &__main{
            width: 100%;
        }
    
        &__intro{
            width: 100%;
        }

        &__featureList{
            margin-top: s(50);
            margin-left: 0;
            width: 100%;
        }
    }
}