.aboutHistory{
    padding-top: s(100);
    margin-bottom: s(70);

    &__inside{
        display: flex;
        padding: 0 col(1,24);
        align-items: flex-start;
    }
 
    &__image{
        width: col(7,22);
        margin-right: col(4,22);
        box-shadow: s(14) s(14) 0 color(shadowGrey);

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content{
        width: col(10,22);
    }

    &__title{
        @extend %subtitle;
    }

    &__intro{
        @extend %intro;
    }

    &__text{
        @extend %body;
    }

    @media screen and (max-width: s(800, 16)){
        padding-top: s(70);
        
        &__image{
            margin-left: col(-1,22);
            margin-right: col(2,22);
            width: col(8,22);
        }

        &__content{
            width: col(13,22);
        }
    }

    @media screen and (max-width: s(600, 16)){

        &__inside{
            flex-direction: column-reverse;
        }
        
        &__content{
            width: col(22,22);
        }

        &__image{
            margin-left: 0;
            width: col(22,22);
            box-shadow: none;
            margin-top: s(50);
        }
    
    }
}

