.projectList{
    padding: 0 col(1,24);
    display: flex;
    margin-bottom: s(100);
    flex-wrap: wrap;

    @media screen and (max-width: em(800,16)){
        .cardProject{
            width: 50%;
        }
        margin-bottom: s(60);
    }

    @media screen and (max-width: em(600,16)){
        .cardProject{
            width: 100%;
        }
    }
}