// GRID

$default-grid: (
    unit: 1em,
    numberOfColumn: 24,
    width: 1066,
    gutterWidth: 0,
    columnWidth: 45,
    outside: false
);

$columnWidth :  ( 
                    map-get($default-grid, width)
                    - 
                    (
                        (map-get($default-grid, numberOfColumn) - 1) 
                        *
                        map-get($default-grid, gutterWidth)
                    )
                )
                /
                map-get($default-grid, numberOfColumn);

//  - fixedGut( $col: number )
// **********************************************************

@function fixedGut($gut){
    @if $gut == 0 { @return 0 }
    @return    ($gut * map-get($default-grid, gutterWidth));
}

//  - fixedCol( $col: number )
// **********************************************************
// returns size based on the specified amount of columns.
// uses the units you chose in config/global
// **********************************************************

@function fixedCol($col){
    @if $col == 0 { @return 0 }
    @return ( ( $col * $columnWidth ) + ( ( $col - 1 ) * map-get($default-grid, gutterWidth) ) ) *map-get($default-grid, unit);
}

//  - col( $col: number, $parent: number )
// **********************************************************
// returns box size in %
// **********************************************************

@function col($col,$parent){
    @if $col == 0 { @return 0 }
    @return percentage( fixedCol($col) / fixedCol($parent) );
}


// - gutter( $parent: number )
// **********************************************************
// returns gutter size in % depending on number
// of parent-columns
// **********************************************************

@function gutter($parent){
    @if $parent == 0 { @return 0 }
    @return percentage((map-get($default-grid, gutterWidth) *map-get($default-grid, unit)) / fixedCol($parent) );
}

//  - colGut( $col: number, $colParent: number, $gutParent: number )
// **********************************************************
// returns box size in %
// **********************************************************

@function colGut($col, $gut, $colParent, $gutParent){
    @return percentage(rmUnit(fixedCol($col) + fixedGut($gut)) / rmUnit(fixedCol($colParent) + fixedGut($gutParent)));
}


// - @include respVideoContainer( $col: number, $parent: number )
// **********************************************************
// Uses col among other properties to create a
// video container (iframe) for the amout of columns in
// a certain amount of parent-columns
// **********************************************************

@mixin respVideoContainer( $col, $parent){
    width: col($col, $parent);
    padding-bottom: ( col($col, $parent) * 0.5625 );
    height: 0;
    position: relative;
    & iframe{
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top:0;
        left:0;
    }
}