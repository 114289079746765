.jobsSection{
    margin-top: s(120);
    margin-bottom: s(100);
    padding: 0 col(1,24);
    display: flex;
    align-items: flex-start;

    &--download{
        margin-top: s(100);
        margin-bottom: s(100);
    }
 
    &__image{
        width: col(7,22);
        margin-right: col(4,22);
        box-shadow: s(14) s(14) 0 color(shadowGrey);

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content{
        width: col(10,22);
    }

    &__section{
        &+&{
            margin-top: s(60);
        }
    }

    &__subtitle{
        @extend %subtitle;
    }

    &__title{
        @extend %title;
        margin-bottom: s(25);
    }

    &__text{
        @extend %body;
    }

    &__btn{
        margin-top: s(26);
    }

    &__offer{
        margin-top: s(70-16);
    }

    .offer{
        display: block;
        padding: s(16) 0;
        text-decoration: none;
        font-weight: 600;
        color: #7a7575;
        font-size: s(10);
        text-transform: uppercase;
        letter-spacing: ps_ls(50);
        @include clearfix();
        border-bottom: 1px solid rgba(0,0,0,0.1);
        transition: 200ms color easing(ease-out-quad);

        &:hover,
        &:focus{
            color: color(footer);
        }
        .offerName{
            float: left;
        }
        .offerLabel{
            float: right;
            color: color(lightRed);
            position: relative;
            &:after{
                padding-left: s(9);
                padding-right: s(17);
                @include icon(aRight);
                font-size: s(9);
            }
        }
    }
    

    @media screen and (max-width: em(800, 16)){
        margin-top: s(80);
        margin-bottom: s(150);

        &__image{
            display: none;
        }

        &__content{
            width: 100%;
        }
    }
}