.errorPage{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc( 100vh - 130px ); 
    

    &__center{
        max-width: s(710);
    }

    &__title{
        font-size: s(60);
        font-weight: 700;
    }

    &__backHome{
        margin-top: s(40);
    }

    @media screen and (max-width: em(map-get($default-grid, width) + 40 + 40, 16)) {
        height: calc( 100vh - 100px ); 

    }
}