.testimony{
    background-color: #f4f4f4;
    text-align: center;
    &__inside{
        padding-top: s(120);
        padding-bottom: s(90);
    }

    &__image{
        position: absolute;
        top: s(-75);
        left: 50%;
        margin-left: s(-75);
        overflow: hidden;
        border-radius: 50%;
        width: s(150);
        height: s(150);
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__text{
        color: color(footer);
        font-size: s(20);
        line-height: s(26);

        &:before{
            content: '«';
        }

        &:after{
            content:'»'
        }
    }
    
    &__footer{
        margin-top: s(20);
        color: #aca8a8;
        font-size: s(12);
        line-height: s(16);
        letter-spacing: ps_ls(50);
        text-transform: uppercase;
        font-weight: 600;
    }

    @media screen and (max-width: em(600, 16)){
        &__text{
            font-size: s(18);
            line-height: s(24);
        }
        &__inside{
            padding-top: s(120);
            padding-bottom: s(60);
            padding-right: col(1,24);
            padding-left: col(1,24);
        }
        br{
            display: none;
        }
    }
}