.featuredProject{
    display: flex;
    height: s(350);
    margin-bottom: s(150);
    overflow: hidden;


    @media screen and (max-width: em(1000,16)){
        flex-direction: column;
        height: inherit;
        margin-bottom: s(90);
    }

    @media screen and (max-width: em(600,16)){
        margin-left: col(1,24);
        margin-right: col(1,24);
    }

    &__header{
        width: 25%;
        padding: s(60);
        background-color: color(footer);
    }

    &__content{
        & + &{
            margin-top: s(30);
            padding-top: s(30);
            border-top: 1px solid rgba(color(white), 0.2);
        }
    }

    &__title{
        display: block;
        text-decoration: none;
        color: color(white);
        font-size: s(15);
        line-height: s(18);
        font-weight: 600;
        &:before{
            @include icon(saBottom);
            font-size: s(7);
            color: color(lightRed);
            vertical-align: middle;
            margin-right: s(15);
            transform-origin: center center;
            transform: rotate(-90deg);
            transition: 200ms transform easing(ease-out-quad);
            display: inline-block;
        }

        .featuredProject__content--active &:before{
            transform: rotate(0deg);
        }


    }

    &__text{

        color: #cfcece;
        font-size: s(16);
        line-height: s(20);
        font-weight: 500;
        max-width: s(210);
        max-height: s(0);
        overflow: hidden;
        opacity: 0;
        transition: 200ms max-height easing(ease-out-quad) 200ms, 200ms opacity easing(ease-out-quad);

        .featuredProject__content--active &{
            max-height: s(200);
            opacity: 1;
            transition: 200ms max-height easing(ease-out-quad), 200ms opacity easing(ease-out-quad) 300ms;

        }


        .no-js &{
            opacity: 1;
            max-height: s(300);
        }
    }

    &__textInside{
        padding-top: s(27);
    }

    &__sectionList{
        width: 75%;
        position: relative;
    }

    &__section{
        @include cover();
        display: flex;
        opacity: 0;
        visibility: hidden;
        transition: 200ms opacity easing(ease-in-quad), 0ms visibility linear 200ms;

        &--active{
            opacity: 1;
            visibility: visible;
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 00ms;
        }
    }

    @media screen and (max-width: em(1200,16)){
        flex-direction: column;
        height: inherit;

        &__header{
            width: 100%;
            display: flex;
            align-items: center;
            background-color: inherit;
            padding: 0;
        }

        &__text{
            display: none;
        }

        &__sectionList{
            width: 100%;
            height: s(350);
        }

        &__content{
            width: 50%;
            background-color: rgba(color(footer), 0.9);
            transition: 200ms background-color easing(ease-out-quad);
            &+&{
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }

            &--active{
                background-color: color(footer);
                        transform: rotate(0deg);

                .featuredProject__title{
                    color: rgba(color(white), 1);

                    &:before{
                        transform: rotate(0deg);
                        opacity: 1;
                    }
                }
            }
        }

        &__title{
            text-align: center;
            padding: s(30);
            position: relative;
            color: rgba(color(white), 0.5);
            transition: 200ms color easing(ease-out-quad);

            &:before{
                position: absolute;
                bottom: s(15);
                transform: rotate(0deg);
                left: 0;
                right: 0;
                margin-right: 0;
                opacity: 0;
                transition: 200ms opacity easing(ease-out-quad);
            }
        }
    }

    @media screen and (max-width: em(600,16)){
        &__sectionList{
            height: s(350*3);
        }

        &__section{
            flex-direction: column;
        }
    
    }
    
}