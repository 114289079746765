.generalContact{
    padding-bottom: s(32);
    border-bottom: 1px solid rgba(#000, 0.1);

    &__title{
        font-size: s(18);
        color: #2d2a2a;
        margin-bottom: s(18);
    }

    &__address{
        color: #ed1c24;
        font-size: s(18);
        text-decoration: none;
    }
}

.contactSelector{
    margin-top: s(35);

    &__label{
        font-size: s(18);
        color: #2d2a2a;
        margin-bottom: s(11);
        display: block;
    }

    &__list{
        margin-top: s(24);
        position: relative;
        height: s(65);
    }

    &__item{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 200ms;

        &--active{
            opacity: 1;
            visibility: visible;
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 0ms;    
        }
    }

    &__email,
    &__phone{
        font-size: s(18);
        color: #ed1c24;
        display: block;
        text-decoration: none;

        & + &{
            margin-top: s(12);
        }
    }
}



.field{
    margin-top: s(15);
    textarea{
        height: s(130);
        resize: none; 
    }

    &__label{
        color: color(footer);
        font-size: s(14);
        line-height: s(18);
        margin-bottom: s(5);
        display: block;
    }

    &__input{
        display: block;
        width: 100%;
        font-size: s(12);
        line-height: s(22);
        padding: s(14) s(15);
        -webkit-appearance: none;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        background-color: #f7f7f7;
        font-family: 'Hind', sans-serif;
        color: color(footer);
        outline: none;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #9d9c9c;
            opacity: 1; /* Firefox */
          }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #9d9c9c;
        }
          
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #9d9c9c;
        }


    }

    &--required{
        position: relative;
        &:after{
            content: '*';
            position: absolute;
            right: s(20);
            top: s(29);
            font-size: s(16);
            margin-top: -0.5em;
            color: #9d9c9c;

        }
    }

    &--textearea{
        .field__input{
            height: s(130);
        }
    }


    &--select{
        position: relative;

        .field__input{
            color: #9d9c9c;;
            padding-right: s(40);

            &:focus{
                color: color(footer);
            }
        }

        .field__inputWrapper{
            position: relative;
            &:after{
                position: absolute;
                right: s(20);
                top: 50%;
                margin-top: -0.5em;
                @include icon(triangle);
                font-size: s(6);
                color: #a8a8a8;
            }
        }
    }
}