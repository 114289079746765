.footerMenu{
    li{
        display: block;
        & + li{
            margin-top: s(16);
        }
    }
    a{
        font-size: s(12);
        color: #aeaeae;
        text-transform: uppercase;
        text-decoration: none;
        transition: 200ms color easing(ease-out-quad);

        &:hover,
        &:focus{
            color: color(white);

        }
    }
}