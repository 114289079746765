.categoryIntro{
    margin-top: s(100);
    margin-bottom: s(70);
    padding: 0 col(1,24);
    display: flex;
    align-items: center;
 
    &__image{
        width: col(7,22);
        margin-right: col(4,22);
        box-shadow: s(14) s(14) 0 color(shadowGrey);

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content{
        width: col(10,22);
    }

    &__title{
        @extend %bigTitle;
    }

    &__text{
        @extend %body;
        //margin-bottom: s(24);
    }

    @media screen and (max-width: em(800,16)){
        margin-top: s(70);
        margin-bottom: s(50);
        &__image{
            width: col(9,22);
            margin-right: col(2,22);
            margin-left: col(-1,22);
            box-shadow: none;
    
            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &__content{
            width: col(12,22);
        }
        
    }

    @media screen and (max-width: em(600,16)){
        margin-top: s(50);
        flex-direction: column-reverse;
        &__content{
            width: col(22,22);
        }
        &__image{
            width: col(22,22);
            margin-right: 0;
            margin-left: 0;
            max-width: s(310);
            margin: 0 auto;
            margin-top: s(25);
        }
    }

}