.gotomenu{
    @include hidden();

    @media screen and (max-width: em(1200)) {
        position: absolute;
        left: initial;
        right: 0;
        top: 0;
        width: s(50);
        height: s(50);
        z-index: 100;
    }
}
.burger{
    height: s(30);
    width: s(50);

    display: inline-block;
    z-index: 11;


    

    &__medium,
    &:before,
    &:after{
        background-color: color(footer);
        position: absolute;
        left: s(15);
        right: s(15);
        height: s(2);
        border-radius: 5px;
        transition: 200ms color linear;
    }

    &__medium{
        top: 50%;
        margin-top: s(-1.5);
        text-indent: -9999px;
        transform: translate3d(0,0,0);
        opacity: 1;
        transition: 150ms transform easing(ease-out-quad) 150ms,
                    100ms opacity linear 150ms;
    }

    &:before,
    &:after{
        content: '';
        transition: 150ms transform easing(ease-out-quad) 0ms;
    }

    &:before{
        top: 50%;
        margin-top: -8.5px;
        transform-origin: center center;
    }
    &:after{
        top: 50%;
        margin-top: 5.5px;
        transform-origin: center center;
    }


    .menuOpen &{

        &__medium{
            transform: translate3d(-15px,0,0);
            opacity: 0;
            transition: 150ms transform easing(ease-out-quad) 0ms,
                        150ms opacity linear 0ms;
        }

        &:before{
            transform: translate3d(0,8.5px,0) rotate(45deg);
            transition: 150ms transform easing(ease-out-quad) 150ms;
        }
        &:after{
            transform:  translate3d(0,-5.5px,0) rotate(-45deg);
            transition: 150ms transform easing(ease-out-quad) 150ms;
        }
    }
}
