.projectSlider{
    width: col(16, 24);
    margin: 0 auto;

    margin-top: s(-110);
    margin-bottom: s(90);
    position: relative;

    &__slide{

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    &__prev,
    &__next{
        top: 50%;
        margin-top: s(-38/2);
        width: s(38);
        height: s(38);
        background-color: color(lightRed);
        border-radius: s(38);
        text-indent: -9999px;
        text-decoration: none;
        position: absolute;
        transition: 200ms background-color easing(ease-out-quad);

        &:before{
            @include iconBase();
            position: absolute;
            top: 50%;
            margin-top: -0.5em;
            left: 0;
            right: 0;
            text-align: center;
            color: color(white);
            text-indent: 0;
            font-size: s(9);
        }

        &:hover,
        &:focus{
            background-color: darken(color(lightRed), 10%);

        }

    }

    &__prev{
        left: s(-38 - 40);
        &:before{
            @include iconInclude(aLeft)
        }
    }

    &__next{
        right: s(-38 - 40);
        &:before{
            @include iconInclude(aRight)
        }
    }

    &__bulletList{
        display: none;
        position: absolute;
    }

    &__bulletList{
        position: absolute;
        left: 0;
        right: 0;
        bottom: s(-20-10);
        display: flex;
        justify-content: center;
    }

    &__bullet{
        text-indent: -9999px;
        display: block;
        width: s(20);
        height: s(20);
        position: relative;
        
        &:before{
            content: '';
            text-indent: 0;
            top: s(7);
            left: s(7);
            position: absolute;
            width: s(6);
            height: s(6);
            border-radius: s(6);
            background-color: #b6b5b5;
        }
        
        &:hover,
        &--active{
            &:before{
                background-color: color(lightRed);
            }
        }
    }

    @media screen and (max-width: em(1024,16)){
        margin-top: s(-85);
        width: col(18, 24);
    }

    @media screen and (max-width: em(800,16)){

        //margin-top: 0;
        width: col(20, 24);

        &__slide{

        }

        &__prev{
            left: s(-38/2);
            &:before{
                @include iconInclude(aLeft)
            }
        }
    
        &__next{
            right: s(-38/2);
            &:before{
                @include iconInclude(aRight)
            }
        }
    }

    @media screen and (max-width: em(600,16)){

        margin-top: s(-30);

        &__prev,
        &__next{
            display: none;
        }
    }



}