.homeTrust{
    
    &__inside{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 col(3,24);
        margin-bottom: s(125);
        
    }

    &__content{
        position: relative;
        width: col(8, 20);
        text-align: center;
    }

    &__title{
        font-size: s(18);
        line-height: s(18);
        font-weight: 600;
        color: color(footer);
        margin-bottom: s(20);
        padding-bottom: s(20);
        position: relative;

        &:before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: s(-14);
            width: s(28);
            height: s(2);
            background-color: color(lightRed);
        }
    }

    &__text{
        color: #828282;
        font-size: s(16);
        line-height: s(22);
        font-weight: 500;
    }

    &__slider{
        margin-top: s(60);
        width: 100%;
        height: s(280);
        position: relative;
    }

    &__slide{
        width: 100%;
        display: flex;
        //align-items: center;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;

        .js &{
            opacity: 0;
            visibility: hidden;
            @include cover();
            bottom: inherit;
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 200ms;

            &--active{
                opacity: 1;
                visibility: visible;
                transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 0ms;
            }
        }
    }


    &__client{
        display: block;
        width: 25%;
        height: s(140);
        border-right: 1px solid #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
            border-bottom: 1px solid #f0f0f0;
        }

        &:nth-child(4),
        &:nth-child(8){
            border-right: none;
        }

        img{
            display: block;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

    }

    &__sliderBullet{
        position: absolute;
        left: 0;
        right: 0;
        bottom: s(-45);
        display: flex;
        justify-content: center;
    }

    &__sliderBulletItem{
        text-indent: -9999px;
        display: block;
        width: s(20);
        height: s(20);
        position: relative;
        
        &:before{
            content: '';
            text-indent: 0;
            top: s(7);
            left: s(7);
            position: absolute;
            width: s(6);
            height: s(6);
            border-radius: s(6);
            background-color: #b6b5b5;
        }
        
        &:hover,
        &:focus,
        &--active{
            &:before{
                background-color: color(lightRed);
            }
        }
    }

    @media screen and (max-width: em(800, 16)){
    
        &__client{
            img{
                max-width: 80%;
            }
        }
    }

    @media screen and (max-width: em(600, 16)){

        &__content{
            position: relative;
            width: col(20, 20);
            text-align: center;
        }

        &__slider{
            height: s(280*2);
        }
        &__client{
            width: 50%;
            border: none !important;
        }
    }


}