.bannerArticle{
    min-height: s(344 - 100);
    background-color: color(footer);
    padding: s(53) col(2,24) s(45) col(2,24);

    &__inside{
        max-width: s(710);
        margin: 0 auto;
    }

    &__all{
        margin-bottom: s(58);
        display: block;
        font-size: s(12);
        line-height: s(18);
        text-transform: uppercase;
        text-decoration: none;
        color:color(white);
        &:before{
            @include icon(aLeft);
            font-size: s(10);
            color: color(lightRed);
            padding-right: s(14);
            vertical-align: middle;
        }
    }

    &__date{
        font-weight: 700;
        font-size: s(12);
        line-height: s(18);
        color: color(lightRed);
        margin-bottom: s(16);
        display: block;
        text-transform: uppercase;
    }

    &__title{
        width: col(13,16);
        font-size: s(24);
        line-height: s(30);
        color: color(white);
        font-weight: 700;
    }

    @media screen and (max-width: em(1024)) {
        min-height: 0;
        &__all{
            display: none;
        }
        &__title{
            width: 100%;
        }
    }
}

.imageArticle{
    position: relative;
    margin-top: s(-1);
    padding: 0 col(2,24) 0 col(2,24);

    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: s(100);
        background-color: color(footer);
    }

    &__figure{
        max-width: s(710);
        margin: 0 auto;
        img{
            width: 100%;
            height: auto;
            display: block;
        }
    }

    
    
}


.articleFooter{
    margin-top: s(100);

    height: s(70);
    position: relative;
    background-color: #f4f4f4;

    &__button{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}