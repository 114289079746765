html{
    height: inherit;
    min-height: 100%;

}
body {
    position: relative;
    width: 100%;
    height: inherit;
    min-height: 100%;
    font-family: 'Hind', sans-serif;

    &.menuOpen{
        overflow: hidden;
        height: 100VH;
    }
}

.wrapper {
    position: relative;
    margin: 0 auto;
    max-width: s(map-get($default-grid, width), 16);

    @media screen and (max-width: em(map-get($default-grid, width) + 40 + 40, 16)) {
        margin: 0;
    }
}

.sro {
    @extend %hidden;
}

