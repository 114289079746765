.header{
    box-shadow: 0 0 s(29)  rgba(color(black), 0.61);
    position: relative;
    z-index: 3;

    
    .wrapper{
        max-width: s(map-get($default-grid, width) + 40 + 40, 16);
    }

    &__main{
        background-color: color(white);
        z-index: 1;
        position: relative;
    }
    &__inside{
        padding-top: s(16);
        padding-bottom: s(11);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo{
        width: col(5, 26);
    }

    &__logoImage{
        max-width: s(169);
        width: 100%;
        display: block;
        height: auto;
    }

    &__side{
        //width: col(20, 26);
        display: flex;
        flex-direction: column-reverse;
    }

    &__top{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-decoration: none;
        position: relative;
    }

    .socialMenu{
        margin-left: s(30);
    }

    &__immo{
        margin-left: s(30);
    }

    &__phone{
        font-size: s(14);
        margin-left: s(30);

        color: #221e1f;
        text-decoration: none;
        display: flex;

        transition: 200ms color easing(ease-out-quad);
        &:hover,
        &:focus{
            color: #ed1c24;
        }

        &:before{
            color: color(lightRed);
            font-size: s(12);
            margin-right: s(6);
            @include icon(sPhone)
        }
    }

    &__gohome{
        text-indent: -9999px;
        width: s(30);
        height: s(30);
        border-radius: s(30);
        background-color: #d33535;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: s(-15);
        transition: 200ms background-color easing(ease-out-quad);

        &:hover,
        &:focus{
            background-color: darken(#d33535, 10%);
        }
        &:before{
            @include icon(home);
            position: absolute;
            top: 50%;
            margin-top: -0.55em;
            left: 0;
            right: 0;
            text-align: center;
            text-indent: 0;
            color: color(white);
            font-size: s(17);
        }
    }

    &__slogan{
        font-size: s(18);
        padding-left: s(30+12);
        color: #221e20;
        position: absolute;
        left: 0;
        vertical-align: middle;
        em{
            font-weight: 700;
            color: #d33535;
        }
    }

    &__searchMobileButton{
        display: none;
    }

    &__search{
        transition: 200ms opacity easing(ease-out-quad), 200ms transform easing(ease-out-quad);
        transform: translate3d(0, -100%, 0);
        opacity: 0.5;
        background-color: rgba(#221f1f, 0.9);
        padding: s(12);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        label{
            @include hidden();
        }

        &--active{
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }

    &__searchForm{
        margin: 0 col(2,26);
        display: flex;
    }

    &__searchInput{
        outline: none;
        -webkit-appearance: none;
        width: calc(100% - 90px);
        font-family: 'Hind', sans-serif;
        border: none;
        padding: s(14) s(13) s(11) s(13);
        font-size: s(12);
        line-height: s(22);
        border-radius: 5px 0 0 5px;
        margin: 0;
    }

    &__searchSubmit{
        position: relative;
        width: s(90);
        border: none;
        background-color: color(red);
        padding: 0;
        border-radius: 0 5px 5px 0;
        outline: none;
        -webkit-appearance: none;
        text-indent: -9999px;
        &:before{
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: -0.5em;
            text-align: center;
            text-indent: 0;
            @include icon(search);
            font-size: s(15);
            color: color(white);
        }

        &:hover,
        &:focus{
            background-color: darken(#d33535, 10%);
        }

    }

    @media screen and (max-width: em(1200, 16)){

        .wrapper{
            margin: 0 col(1,24)
        }
        &__logo{
            width: inherit;
        }

        &__logoImage{
            max-width: s(157/2);
        }

        &__inside{
            padding: 0;
            height: s(100/2);
        }
        
        &__side{
            @include hidden();
        }





        &__searchForm{
            padding: s(35) 0;
        }
    }

    @media screen and (max-width: em(1200, 16)){
        &__searchForm{
            flex-direction: column;
        }
        &__searchInput{
            border-radius: 5px 5px 0 0;
            width: 100%;
        }
        &__searchSubmit{
            height: s(47);
            width: 100%;
            border-radius: 0 0 5px 5px;
        }
    }
}

.searchMobileButton{
    display: none;

    @media screen and (max-width: em(1200, 16)){
        display: block;
        position: absolute;
        right: s(50);
        top: 0;
        width: s(50);
        height: s(50);
        margin: 0;
        border-left: 1px solid rgba(#ababab, 0.23);
        border-right: 1px solid rgba(#ababab, 0.23);

        text-align: center;
        z-index: 99;
        text-indent: -9999px;
        color: color(footer);
        text-decoration: none;



            &:before{
                @include icon(search);
                position: absolute;
                font-size: s(33/2);
                text-indent: 0;
                top: 50%;
                right: 0;
                left: 0;
                margin-top: -0.5em;

            }

    }
}
