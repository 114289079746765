.newsList{
    margin-top: s(-63);
    padding: 0 col(2,24);
    margin-bottom: s(70);

    &__item{
        position: relative;
        display: flex;
        background-color: #f4f4f4;
        box-shadow: 0px 0px 10px rgba(color(black), 0.24);

        & + &{
            margin-top: s(36);
        }
    }

    &__link{
        @include cover();
        &:hover,
        &:focus{
            .newsList__linkInside{
                opacity: 1;
            }
        }

    }

    &__linkInside{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: col(7,20);
        background-color: rgba(#333, 0.72);
        display: flex;
        align-items: center;
        justify-content: center;
        color: color(white);
        opacity: 0;
        transition: 200ms opacity easing(ease-out-quad);
    }

    &__linkLabel{
        display: block;
        text-align: center;
        font-size: s(10);

        letter-spacing: ps_ls(50);
        color: color(white);
        text-transform: uppercase;
        font-weight: 600;

        &:before{
            @include icon(plus);
            display: block;
            font-size: s(16);
            margin-bottom: s(16);
        }
    }

    &__image{
        width: col(7,20);
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &__content{
        width: col(13,20);
        padding: s(50) col(2, 20);
    }

    &__date{
        text-transform: uppercase;
        font-weight: 700;
        font-size: s(14);
        line-height: s(18);
        color: color(lightRed);
        margin-bottom: s(22);
        display: block;
    }

    &__title{
        font-size: s(18);
        line-height: s(23);
        font-weight: 700;
        color: color(footer)
    }

    &__description{
        margin-top: s(22);
        @extend %body;
        line-height: s(22);
        color: color(footer);
    }

    @media screen and (max-width: em(1200,16)){
        &__content{
            padding: s(40) col(1, 20);
        }
    }

    @media screen and (max-width: em(1000,16)){
        &__content{
            padding: s(40) col(1, 20);
        }

        &__description{
            display: none;
        }
    }

    @media screen and (max-width: em(600,16)){
        &__item{
            flex-direction: column;
        }

        &__content{
            width: col(20,20);
            padding: s(30);
        }

        &__linkInside{
            display: none;
        }
        
        &__description{
            display: block;
        }

        &__image{
            width: col(20,20);
        }
    }
}