.postContent{
    @extend %body;
    max-width: s(710);
    margin: s(120) auto s(120) auto;

    &--article{
        margin-top: s(75);
        margin-bottom: s(100);
    }

    @media screen and (max-width: s(800)) {
        margin-top: s(80);
        margin-bottom: s(80);
        margin-left: col(2, 24);
        margin-right: col(2, 24);
        width: inherit

        &--article{
            margin-top: s(60);
            margin-bottom: s(80);
        }
    }
}