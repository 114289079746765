.contactPage{
    &__inside{
        display: flex;
        padding: s(100) col(3,24);
    }

    &__infos{
        width: col(5,18);
        margin-right: col(1,18);
        color: color(footer);
        line-height: s(18);
    }




    &__address,
    &__companyName,
    &__contact{
        font-size: s(16);
        line-height: s(18);
    }
    .socialMenu{
        margin-top: s(22);
    }
    &__contact,
    &__address{
        margin-top: s(22);
        display: block;
    }

    &__address,
    &__contact a{
        text-decoration: none;
        color: color(footer);

        &:hover,
        &:focus{
            text-decoration: underline;
        }
    }

    

    

    &__title{
        font-size: s(16);
        letter-spacing: ps_ls(50);
        font-weight: 600;
        color: color(lightRed);
        text-transform: uppercase;
        margin-bottom: s(30);
    }



    &__form{
        width: col(12,18);
        padding-left: col(2,18);
        border-left: 1px solid rgba(0,0,0,0.1);

    }

    @media screen and (max-width: em(1000,16)){
        &__inside{
            flex-direction: column;
            padding: s(50) col(2,24);
        }

        &__infos{
            width: 100%;
            margin-bottom: s(50);
        }

        &__form{
            width: 100%;
            padding-left: 0;
            border-left: none;
        }
    }

    &__selector{
        display: flex;
        width: 100%;
        border: 1px solid #a8a8a8;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: s(40);
    }

    &__selectorItem{
        position: relative;
        width: 50%;
        text-align: center;
        text-decoration: none;
        font-weight: 500;
        color: rgba(#d33535, 0.84);
        padding: s(16);

        transition: 200ms color easing(ease-out-quad), 200ms background-color easing(ease-out-quad);


        &+&{
            border-left: 1px solid #a8a8a8;
        }

        &:before{
            @include icon(aRight);
            font-size: s(9);
            color: color(lightRed);
            position: absolute;
            left: 25px;
            top: 50%;
            margin-top: -0.5em;
            opacity: 0;
            transform: translateX(-10px);
            transition: 200ms opacity easing(ease-out-quad), 200ms transform easing(ease-out-quad);
            @media screen and (max-width: s(600,16)) {
                display: none;
            }
        }

        &:focus,
        &:hover,
        &--active{

            &:before{
                opacity: 1;
                transform: none;
            }
            background-color: #363333;
            color: color(white);
        }

    }
    &__formContainer{
        min-height: s(650);
        position: relative;
        
    }

    &__formItem{
        @include cover();
        opacity: 0;
        visibility: hidden;
        transition: 400ms opacity easing(ease-out-quad), 0ms visibility linear 200ms;

        &--active{
            visibility: visible;
            opacity: 1;
            transition: 400ms opacity easing(ease-out-quad) 200ms, 0ms visibility linear 0ms;

        }
    }

    &__submit{
        margin-top: s(20);
    }

    hr{
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: none;
        margin: s(18) 0;
    }

    .message{
        text-transform: uppercase;
        font-size: s(12);
        line-height: s(18);
        letter-spacing: ps_ls(50);
        font-weight: 500;

        &.error{
            color: color(lightRed);
        }

        &.notice{
            color: green;
        }
    }

    .errors{
        text-transform: uppercase;
        font-size: s(12);
        text-align: right;
        letter-spacing: ps_ls(50);
        color: color(lightRed);
        margin-top: s(10);
        font-weight: 500;
    }

    &__map{
        position: relative;
        min-height: s(460);
        background-color: #ebe9e4;
    }

    &__mapObject{
        @include cover();
        
    }

    &__mapFooter{
        height: s(130);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(color(footer), 0.88);
    }

    &__mapLink{
        @extend .btn;
        background-color: transparent;
        border: 1px solid color(lightRed);

        &:hover,
        &:focus{
            background-color: color(lightRed);
        }

    }

}

.field{
    margin-top: s(15);
    textarea{
        height: s(130);
        resize: none; 
    }

    &__label{
        color: color(footer);
        font-size: s(14);
        line-height: s(18);
        margin-bottom: s(5);
        display: block;
    }

    &__input{
        display: block;
        width: 100%;
        font-size: s(12);
        line-height: s(22);
        padding: s(14) s(15);
        -webkit-appearance: none;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        background-color: #f7f7f7;
        font-family: 'Hind', sans-serif;
        color: color(footer);
        outline: none;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #9d9c9c;
            opacity: 1; /* Firefox */
          }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #9d9c9c;
        }
          
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #9d9c9c;
        }


    }

    &--required{
        position: relative;
        &:after{
            content: '*';
            position: absolute;
            right: s(20);
            top: s(29);
            font-size: s(16);
            margin-top: -0.5em;
            color: #9d9c9c;

        }
    }

    &--textearea{
        .field__input{
            height: s(130);
        }
    }


    &--select{
        position: relative;

        .field__input{
            color: #9d9c9c;;
            padding-right: s(40);

            &:focus{
                color: color(footer);
            }
        }

        .field__inputWrapper{
            position: relative;
            &:after{
                position: absolute;
                right: s(20);
                top: 50%;
                margin-top: -0.5em;
                @include icon(triangle);
                font-size: s(6);
                color: #a8a8a8;
            }
        }
    }
}