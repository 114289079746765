.homeCategories{
    &__inside{
        margin-top: s(-54);

        padding: 0 col(1,24);
        display: flex;
        justify-content: center;
    }

    &__item{
        width: 33.3333%;
        height: 0;
        padding-top: 198 / 328 * 33.3333%;
        position: relative;
    }

    &__image{
        @include cover();
        img{
            @include cover();
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:after{
            content: '';
            @include cover();
            background-color: rgba(#1a1b1b, 0.15);

        }
    }

    &__text{
        @include cover();
        padding: s(28) s(24);
        font-weight: 500;
        z-index: 1;
        background-color: transparent;
        transition: 200ms background-color easing(ease-out-quad);

        &:after{
            @include icon(plus);
            position: absolute;
            right: s(24);
            bottom: s(28);
            color: color(white);
            opacity: 0;
            transition: 200ms opacity easing(ease-out-quad);
        }
    }

    &__title{
        text-transform: uppercase;
        font-weight: 600;
        font-size: s(12);
        letter-spacing: ps_ls(50);
        color: color(white);
        margin-bottom: s(30);

        &:after{
            @include icon(aRight);
            font-size: s(9);
            margin-left: s(13);
        }
    }

    &__description{
        font-size: s(14);
        line-height: s(18);
        font-weight: 600;
        color: rgba(color(white), 0.5);
        opacity: 0;
        transition: 200ms opacity easing(ease-out-quad);
    }

    &__link{
        z-index: 2;
        @include cover();
        text-indent: -9999px;
    }

    &__link:hover + &__text,
    &__link:focus + &__text{
        background-color: #d33535;

        &:after,
        .homeCategories__description{
            opacity: 1;
            transition-delay: 100ms;
        }

    }

    @media screen and (max-width: em(1000, 16)) {
        .wrapper{
            margin: 0;
        }

        
        &__inside{
            margin-top: 0;
            padding: 0 col(0,24);

        }

        &__item{
        }
    }

    @media screen and (max-width: em(600, 16)) {
        &__inside{
            margin-top: s(-20);
            padding: 0 col(2,24);
            flex-direction: column;
        }
        
        &__item{
            width: 100%;

            padding-top: 130 / 210 * 100%;



        }
    }
}