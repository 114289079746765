.mobileMenu{
    @include hidden();
    background-color: color(footer);
    opacity: 0;
    visibility: hidden;
    transition: 400ms opacity easing(ease-out-quad), 0ms visibility linear 400ms;
    padding: s(45) col(2,24) s(105) col(2,24);
    overflow: scroll;

    -webkit-overflow-scrolling: touch; /* on laisse le défilement se poursuivre */
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;

    overflow-y: scroll;
    margin-top: 1px;
    height: calc(100vh - 50px);
    
    @media screen and (max-width: em(1200)) {
        left: 0;
        right: 0;
        top: s(50);
    }
    .menuOpen & {
        z-index: 99;
        opacity: 1;
        visibility: visible;
        transition: 400ms opacity easing(ease-out-quad), 0ms visibility linear 0ms;
    
    }

    &__menu{
        .menu-item{
            a{
                font-size: s(12);
                line-height: s(18);
                text-transform: uppercase;
                color: color(white);
                text-decoration: none;
                padding: s(8) 0;
                display: block;
            }
            &:hover,
            &:focus,
            &.current-menu-item a{
                color: color(lightRed);
            }

        }
        .menu-item + .menu-item{
            border-top: 1px solid rgba(#ababab, 0.23);
        }

        @media screen and (min-width: s(601, 16)){
            .menu-item{

                a{
                    text-align: center;
                    font-size: s(18);
                    line-height: s(24);
                    padding: s(15);
                }
            }
        }
    }

    &__footer{
        margin-top: s(25);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}