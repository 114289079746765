.searchSection{
    background-color: #f4f4f4;
    padding: s(70) col(2,24) s(100) col(2,24);
    margin: s(-70) auto s(90) auto;
    width: col(19,24);
    position: relative;

    &__query{
        margin-bottom: s(50);
        font-size: s(14);
    }

    &__queryTerm{
        font-weight: 600;
        font-size: s(14);
        color: color(red);
        text-transform: uppercase;
    }

    &__queryData{
        color: #807e7e;
    }

    &__item{
        position: relative;
        padding-bottom: s(23);
        border-bottom: 1px solid rgba(#000,0.1);

        &+&{
            margin-top: s(34);
        }
    }

    &__link{
        @include cover();
        text-indent: -9999px;
        display: block;

        &:hover,
        &:focus{
            & + .searchSection__title{
                color: color(red);
            }
            & + .searchSection__title + .searchSection__description{
                color: color(footer);
            }
        }
    }

    &__title{
        font-size: s(18);
        font-weight: 700;
        line-height: s(23);
        color: color(footer);
        margin-bottom: s(16);
        transition: 200ms color easing(ease-out-quad);
    }

    &__description{
        font-size: s(16);
        line-height: s(22);
        color: #8a8a8a;
        transition: 200ms color easing(ease-out-quad);

    }

    &__homeButton{
        position: absolute;
        left: col(2,19);
        bottom: 0;
        transform: translateY(50%);
        padding-left: s(50);
        padding-right: s(50);
    }

    @media screen and (max-width: em(600, 16)){
        margin: 0 auto;
        width: 100%;

        &__homeButton{
            position: relative;
            left: 0;
            transform: noner;
        }
    }
}