.mainMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: s(16);
    padding-top: s(16);
    border-top: 1px solid rgba(#000000, 0.23);

    & > li{
        position: relative;
        & > a{
            padding: s(9);
            font-size: s(12);
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: none;
            color: #231f20;
            transition: 200ms color easing(ease-out-quad);
            position: relative;

            &:after{
                opacity: 0;
                content: '';
                position: absolute;
                bottom: s(-7);
                left: s(9);
                right: s(9);
                height: s(7);
                background-color: #ed1c24;
                transition: 200ms opacity easing(ease-out-quad);

            }
            .current-menu-item &,
            &.active,
            &:hover,
            &:focus{
                //color: #ed1c24;
                &:after{
                    opacity: 1;
                }
            }
        }

        &.current-menu-item > a {
            &:after{
                opacity: 1;
            }
        }

        &:first-child > a{
            padding-left: 0;
            &:after{
                left: 0;
            }
        }

        &.search{
            margin-right: s(-4);
            margin-left: s(10);
            width: s(30);
            height: s(30);
            position: relative;
            text-align: center;

            a{
                @include cover();
                text-indent: -9999px;
                padding: 0;
                font-weight: normal;

                &:before{
                    @include icon(search);
                    position: absolute;
                    font-size: s(15);
                    text-indent: 0;
                    top: 50%;
                    right: 0;
                    left: 0;
                    margin-top: -0.5em;
                }

                &:after{
                    display: none;
                }
            }
        }
    }

    li{

        a.dropdown{
            position: relative;
        }
        ul{
            position: absolute;
            z-index: 1;
            top: s(34);
            padding: s(11);
            left: s(9);
            min-width: s(300);
            background-color: rgba(#221f1f, 0.9);
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 200ms;
            visibility: hidden;
            opacity: 0;



            a{
                opacity: 0.5;
                color: white;
                font-size: s(12);
                letter-spacing: ps_ls(50);
                text-decoration: none;
                font-weight: 600;
                text-transform: uppercase;
                transition: 200ms opacity easing(ease-out-quad);
                padding: s(10);
                display: block;

                &:hover,
                &:focus{
                    opacity: 1;
                }
                
            }

            li+li{
                margin-top: s(01);
            }
        }

        ul:hover,
        ul:focus,
        a.dropdown:hover + ul,
        a.dropdown:focus + ul{
            opacity: 1;
            visibility: visible;
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 00ms;

        }
    }
}