.aboutAssets{
    padding-top: s(100);
    background-color: #f4f4f4;
    text-align: center;
    padding-bottom: s(105);

    &__inside{
        //padding-top: s(80);
    }

    &__title{
        font-size: s(14);
        letter-spacing: ps_ls(50);
        font-weight: 600;
        color: color(lightRed);
        text-transform: uppercase;
        margin-bottom: s(25);
    }

    &__text{
        width: col(9,24);
        margin: 0 auto;
        color: color(footer);
        font-size: s(20);
        line-height: s(26);
        margin-bottom: s(85);
        
    }



    &__list{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item{
        width: col(4,24);
    }

    &__image{
        width: s(106);
        height: s(106);
        border-radius: 50%;
        background-color: color(footer);
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            //width: s(46);
            path{
                fill: color(white);
            }
        }



    }

    @media screen and (max-width: s(1024, 16)){
        &__text{
            width: col(16,24);
        }
    }

    @media screen and (max-width: s(800, 16)){


        &__item{
            width: col(5,24);
        }

        &__image{
            width: s(90);
            height: s(90);
        }

    }

    @media screen and (max-width: s(600, 16)){
        &__list{
            flex-direction: column;
        }
        &__item{
            width: col(24,24);
            &+&{
                margin-top: s(30);
            }
        }

        &__text{
            width: col(20,24);
        }

        &__image{
            width: s(106);
            height: s(106);
        }

    }

    &__label{
        display: block;
        margin-top: s(40);
        text-align: center;
        font-weight: 600;
        color: color(footer);
        text-transform: uppercase;
        letter-spacing: ps_ls(50);
        font-size: s(14);
    }
}