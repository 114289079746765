.aboutPhilosophy{
    padding-top: s(85);
    background-color: color(footer);
    color: color(white);
    position: relative;
    //overflow: hidden;

    &__inside{
        display: flex;
        align-items: flex-end;
        padding-left: col(1,24);
    }

    &__content{
        width: col(11,23);
        margin-right: col(3,23);
        padding-bottom: s(85);
    }

    &__title{
        @extend %subtitle;
        margin-bottom: s(26);
    }

    &__intro{
        @extend %intro; 
        color: color(white);
    }

    &__text{
        @extend %body;
        color: color(white);
    }

    &__button{
        margin-top: s(30);
    }

    &__slider{
        position: relative;
        width: col(10,23);
        width: calc(#{col(10,23)} + (100vw - #{ s(map-get($default-grid, width), 16)})/2  );
        margin-right: calc((100vw - #{ s(map-get($default-grid, width)) })/-2  );
        height: s(325);
        //overflow: hidden;


    }

    &__slide{
        @include cover();
        transition: 600ms opacity linear;
        opacity: 0;

        &--prePrev{
        }
        &--prev{
        }

        &--active{
            opacity: 1;
            z-index: 1;
        }

        &--next{
        }

        &--preNext{
        }

        &--prev,
        &--active,
        &--next{
        }


        img{
            width: 100%;
            display: block;
            height: 100%;
            object-fit: cover;
        }
    }

    &__slideCaption{
        text-align: center;
        position: absolute;
        bottom: s(-60);
        left: 0;
        background-color: color(lightRed);
        width: 100%;
        max-width: s(420);
        font-size: s(14);
        line-height: s(20);
        padding: s(35) s(66);

        &:before{
            content: '« '
        }

        &:after{
            content: ' »';
        }

    }

    &__bulletList{
        position: absolute;
        left: s(-7);
        right: 0;
        bottom: s(-90);
        display: flex;
    }

    &__bullet{
        text-indent: -9999px;
        display: block;
        width: s(20);
        height: s(20);
        position: relative;
        
        &:before{
            content: '';
            text-indent: 0;
            top: s(7);
            left: s(7);
            position: absolute;
            width: s(6);
            height: s(6);
            border-radius: s(6);
            background-color: #b6b5b5;
        }
        
        &:hover,
        &:focus,
        &--active{
            &:before{
                background-color: color(lightRed);
            }
        }
    }

    @media screen and (max-width: em(map-get($default-grid, width) + 40 + 40, 16)) {

        &__slider{
            margin-right: s(-40);
            width: col(10,23);
        }

    }

    @media screen and (max-width: s(800, 16)){
        padding-top: s(70);

        &__content{
            padding-bottom: s(70);
        }

        &__inside{
            flex-direction: column;
            align-items: flex-start;
        }

        &__content{
            width: col(16,23);
            margin-right: 0;
            //padding-bottom: s(50);
        }

        &__slider{
            position: relative;
            width: col(22,23);
            margin-right: 0;
            height: s(325);
            
        }
    }

    @media screen and (max-width: s(600, 16)){
        &__content{
            width: col(22,23);
            margin-right: 0;
            padding-bottom: s(50);
        }

        &__slider{

            height: s(200);
            
        }

        &__slideCaption{
            text-align: center;
            position: absolute;
            bottom: 0;
            transform: translate3d(0,100%,0);
        }

        &__bulletList{
            z-index: 3;
            bottom: s(-140);
        }

    }
}

.aboutFeature{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: s(48);

    &__item{
        width: 33.3333%;
    }

    &__number{
        font-size: s(32);
        margin-bottom: s(5);
        font-weight: 600;
        letter-spacing: ps_ls(50);
        color: color(white);
    }

    &__text{
        font-size: s(10);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: ps_ls(50);
        color: color(lightRed);
    }

    &__text,
    &__number{
        display: block;
        text-align: center;
    }

    @media screen and (max-width: s(600, 16)){
        flex-direction: column;
        &__item{
            width: 100%;
            & + &{
                margin-top: s(40);
            }
        }
    }
}