.blockContent{
    padding: 0 col(2,24) 0 col(1, 24);
    margin-bottom: s(100);
    display: flex;
    justify-content: space-between;
    &__item{
        width: col(9,21);

        &:nth-child(2){
            width: col(10,21);
        }
    }

    &__title{
        @extend %title;
        margin-bottom: s(32);
    }

    &__text{
        @extend %body;
    }

    &__button{
        margin-top: s(30);
    }

    @media screen and (max-width: em(800,16)){
        flex-direction: column;
        margin-bottom: s(70);
        padding: 0 col(1,24) 0 col(1, 24);


        &__title{
            @extend %title;
            margin-bottom: s(16);
        }
    
        &__item{
            width: col(22,22);
            &:nth-child(2){
                width: col(22,22);
                margin-top: s(50);
            }
        }
    }
}