.socialMenu{
    display: flex;
    &__item{
        text-indent: -9999px;
        width: s(30);
        height: s(30);
        position: relative;

        &:before{
            text-indent: 0;
            position: absolute;
            top: 50%;
            margin-top: -0.5em;
            left: 0;
            right: 0;
            text-align: center;
            @include iconBase();
            font-size: s(12);
            color: #221e1f;
            transition: 200ms color easing(ease-out-quad);
        }



        &--facebook{
            &:before{
                @include iconInclude(facebook);
            }
            &:hover,
            &:focus{
                &:before{
                    color: #4267B2;
                }
            }
        }

        &--twitter{
            &:before{
                @include iconInclude(twitter);
            }
            &:hover,
            &:focus{
                &:before{
                    color: #1DA1F2;
                }
            }
        }

        &--instagram{
            &:before{
                @include iconInclude(instagram);
            }
            &:hover,
            &:focus{
                &:before{
                    color: #405DE6;
                }
            }
        }
        

        &--youtube{
            &:before{
                @include iconInclude(youtube);
            }
            &:hover,
            &:focus{
                &:before{
                    color: red;
                }
            }
        }

        &--linkedin{
            &:before{
                @include iconInclude(linkedin);
            }
            &:hover,
            &:focus{
                &:before{
                    color: #2867B2;
                }
            }
        }
    }

    &--contact{
        .socialMenu__item{
            &:before{
                font-size: s(14);
                color: color(footer);
            }
        }
    }


    &--footer{
        margin-top: s(39);
        border-top: 1px solid rgba(#827a7a,0.5);
        padding-top: s(32);
        .socialMenu__item{
            &:before{
                font-size: s(17);
                color: #bebebe;
            }
            &:hover,
            &:focus{
                &:before{
                    color: color(white);
                }
            }
        }

        @media screen and (max-width: em(600, 16)){
            flex-direction: column;
            border-left: 1px solid rgba(#827a7a,0.5);
            border-top: none;
            width: col(4,24);
            position: absolute;
            right: col(1,24);
            top: 0;
            padding-top: 0;
            padding-left: col(1,24);

        }
    }

    &--mobile{
        .socialMenu__item{
            &:before{
                font-size: s(12);
                color: color(white);

                @media screen and (min-width: em(601, 16)){
                    font-size: s(17);
                }
            }
        }
    }
    

}