@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?oqrs85');
    src:  url('fonts/icomoon.eot?oqrs85#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?oqrs85') format('truetype'),
      url('fonts/icomoon.woff?oqrs85') format('woff'),
      url('fonts/icomoon.svg?oqrs85#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }  