.footer{
    background-color: color(footer);
    padding-top: s(68);
    padding-bottom: s(46);
    padding-left: col(2,24);
    
    &__top{
        display: flex;
    }

    &__title{
        font-size: s(13);
        line-height: s(18);
        font-weight: 600;
        color: color(lightRed);
        text-transform: uppercase;
        margin-bottom: s(35);
    }

    &__text{
        font-size: s(14);
        line-height: s(18);
        color: color(white);
        margin-bottom: s(26);
    }

    &__logo{
        width: col(3.5,24);
    }

    &__logoItem{
        display: block;
        img{
            display: block;
        }

        & + & {
            margin-top: s(43);
        }
    }

    &__contact{
        margin-left: col(1,24);
        width: col(4.5, 24);
    }

    &__navigation{
        margin-left: col(1,24);
        width: col(4, 24);
    }

    &__newsletter{
        margin-left: col(1,24);
        width: col(6, 24);
    }

    &__newsletterInput{
        background-color: #252424;
        border: 1px solid #595959;
        -webkit-appearance: none;
        font-family: 'Hind', sans-serif;
        font-size: s(12);
        line-height: s(22);
        padding: s(14) s(15);
        border-radius: s(5);
        width: 100%;
        outline: none;
        margin-bottom: s(17);
        color: color(white);
    }

    &__newsletterSubmit{
        width: 100%;
        @extend .btn;
        font-family: 'Hind', sans-serif;

        &:before{
            @include icon(email);
            vertical-align: middle;
            margin-right: s(14);
            font-size: s(14);
            position: relative;
            top: -1px;
        }

    }

    &__bottom{
        margin-top: s(60);
        display: flex;
    }

    &__year{
        color: white;
        opacity: 0.5;
        font-weight: 600;
        font-size: s(10);
        text-transform: uppercase;
        margin-right: s(51);
    }

    @media screen and (max-width: em(1200, 16)){
        padding-left: col(1,24);

        &__contact{
            width: col(5.5, 24);
        }

        &__navigation{
            width: col(4, 24);
        }
        
        &__newsletter{
            width: col(7,24);
        }
    }

    @media screen and (max-width: em(1000, 16)){
        &__logo{
            width: 100%;
            margin-bottom: s(30);

        }

        &__logoItem{
            &:nth-child(2){
                display: none;
            }
        }

        &__logoItem{
            img{
                max-width: s(144);
            }
        }

        &__top{
            flex-wrap: wrap;
        }

        &__contact{
            margin-left: 0;
            width: col(8,24);
        }

        &__newsletter{
            width: col(9,24);
        }
    }

    @media screen and (min-width: em(600, 16)) and (max-width: em(800, 16)){
        &__newsletterSubmit{
            &:before{
                display: none;
            }
        }
    }

    @media screen and (max-width: em(600, 16)){

        &__contact{
            position: relative;
        }

        &__navigation,
        &__contact,
        &__newsletter{
            margin-left: 0;
            width: col(22,24);
            margin-top: s(40);
        }

        &__navigation{
            display: none;
        }

        &__bottom{
            display: block;
            text-align: center;
            width: col(22,24);
            margin-top: s(40);
        }

        &__year{
            margin-right: 0;
            margin-bottom: s(20);
        }
    }
}

.legalMenu{
    color: white;
    font-weight: 600;
    font-size: s(10);
    text-transform: uppercase;
    display: flex;
    li + li{
        margin-left: s(10);
        &:before{
            content:'|';
            margin-right: s(10);
        }
    }
    a{
        color: white;
        text-decoration: none;
    }

    @media screen and (max-width: em(600, 16)){
        flex-direction: column;

        li+li{
            margin-left: 0;
            margin-top: s(20);
            &:before{
                display: none;
            }
        }
        
    }
}

.contactItem{
    padding-left: s(27);
    display: block;
    position: relative;
    font-size: s(13);
    line-height: s(18);
    color: color(white);
    text-decoration: none;

    &:before{
        color: color(lightRed);
        @include iconBase();
        position: absolute;
        left:0;
        top: s(4);
    }
    &--mail{
        &:before{
            font-size: s(8);
            @include iconInclude(sEmail);
        }
    }

    &--phone{
        &:before{
            font-size: s(10);
            @include iconInclude(sPhone);
        }
    }

    &--address{
        &:before{
            font-size: s(10);
            @include iconInclude(sPin);
        }
    }

    & + &{
        margin-top: s(17);
    }




}