@charset "UTF-8";
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.sro {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.btn, .contactPage__mapLink, .sliderItem__button, .footer__newsletterSubmit {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-color: #d33535;
  padding: 0.875rem 1.125rem;
  border-radius: 0.3125rem;
  color: #fff;
  transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: inline-block;
  border: none;
  font-family: "Hind", sans-serif;
}
.btn:hover, .contactPage__mapLink:hover, .sliderItem__button:hover, .footer__newsletterSubmit:hover, .btn:focus, .contactPage__mapLink:focus, .sliderItem__button:focus, .footer__newsletterSubmit:focus {
  background-color: #af2626;
}
.btn--immo {
  position: relative;
  padding-left: 4.375rem;
  overflow: hidden;
}
.btn--immo:before {
  content: "";
  position: absolute;
  left: 0;
  width: 3.125rem;
  top: 0;
  bottom: 0;
  background-color: #a92a2a;
}
.btn--immo:after {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  left: 0;
  width: 3.125rem;
  top: 50%;
  margin-top: -0.5em;
  text-align: center;
  font-size: 1.75rem;
}
@media screen and (max-width: 37.5em) {
  .btn, .contactPage__mapLink, .sliderItem__button, .footer__newsletterSubmit {
    font-size: 0.625rem;
  }
}

.cookies__title, .jobsSection__title, .blockContent__title, .postContent h2, .newsList__description h2, .aboutBi__text h2, .aboutPhilosophy__text h2, .aboutHistory__text h2, .jobsSection__text h2, .project__content h2, .blockContent__text h2, .categoryIntro__text h2, .homeAbout__text h2 {
  text-transform: uppercase;
  color: #2d2a2a;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.aboutPhilosophy__title, .aboutHistory__title, .jobsSection__subtitle {
  padding-left: 3.375rem;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  color: #e43535;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  margin-bottom: 2.625rem;
}
.aboutPhilosophy__title:before, .aboutHistory__title:before, .jobsSection__subtitle:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 0.125rem;
  width: 2.3125rem;
  margin-top: -0.125rem;
  background-color: #e43535;
}

.aboutBi__intro, .aboutPhilosophy__intro, .aboutHistory__intro, .project__intro {
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin-bottom: 2.125rem;
  color: #2d2a2a;
}

.postContent, .newsList__description, .aboutBi__text, .aboutPhilosophy__text, .aboutHistory__text, .jobsSection__text, .project__content, .blockContent__text, .categoryIntro__text, .homeAbout__text {
  color: #2d2a2a;
  font-size: 1rem;
  line-height: 1.5rem;
}
.postContent p + p, .newsList__description p + p, .aboutBi__text p + p, .aboutPhilosophy__text p + p, .aboutHistory__text p + p, .jobsSection__text p + p, .project__content p + p, .blockContent__text p + p, .categoryIntro__text p + p, .homeAbout__text p + p {
  margin-top: 1.5rem;
}
.postContent b, .newsList__description b, .aboutBi__text b, .aboutPhilosophy__text b, .aboutHistory__text b, .jobsSection__text b, .project__content b, .blockContent__text b, .categoryIntro__text b, .homeAbout__text b, .postContent strong, .newsList__description strong, .aboutBi__text strong, .aboutPhilosophy__text strong, .aboutHistory__text strong, .jobsSection__text strong, .project__content strong, .blockContent__text strong, .categoryIntro__text strong, .homeAbout__text strong {
  font-weight: 700;
}
.postContent a, .newsList__description a, .aboutBi__text a, .aboutPhilosophy__text a, .aboutHistory__text a, .jobsSection__text a, .project__content a, .blockContent__text a, .categoryIntro__text a, .homeAbout__text a {
  color: #ed1c24;
}
.postContent ul, .newsList__description ul, .aboutBi__text ul, .aboutPhilosophy__text ul, .aboutHistory__text ul, .jobsSection__text ul, .project__content ul, .blockContent__text ul, .categoryIntro__text ul, .homeAbout__text ul, .postContent ol, .newsList__description ol, .aboutBi__text ol, .aboutPhilosophy__text ol, .aboutHistory__text ol, .jobsSection__text ol, .project__content ol, .blockContent__text ol, .categoryIntro__text ol, .homeAbout__text ol {
  margin: 2.0625rem 0;
  counter-reset: list;
}
.postContent li, .newsList__description li, .aboutBi__text li, .aboutPhilosophy__text li, .aboutHistory__text li, .jobsSection__text li, .project__content li, .blockContent__text li, .categoryIntro__text li, .homeAbout__text li {
  position: relative;
  display: block;
  padding-left: 2.0625rem;
}
.postContent li + li, .newsList__description li + li, .aboutBi__text li + li, .aboutPhilosophy__text li + li, .aboutHistory__text li + li, .jobsSection__text li + li, .project__content li + li, .blockContent__text li + li, .categoryIntro__text li + li, .homeAbout__text li + li {
  margin-top: 0.875rem;
}
.postContent ul li:before, .newsList__description ul li:before, .aboutBi__text ul li:before, .aboutPhilosophy__text ul li:before, .aboutHistory__text ul li:before, .jobsSection__text ul li:before, .project__content ul li:before, .blockContent__text ul li:before, .categoryIntro__text ul li:before, .homeAbout__text ul li:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  left: 0;
  font-size: 0.5625rem;
  top: 0.40625rem;
  color: #e43535;
}
.postContent ol li:before, .newsList__description ol li:before, .aboutBi__text ol li:before, .aboutPhilosophy__text ol li:before, .aboutHistory__text ol li:before, .jobsSection__text ol li:before, .project__content ol li:before, .blockContent__text ol li:before, .categoryIntro__text ol li:before, .homeAbout__text ol li:before {
  counter-increment: list;
  content: counter(list) ". ";
  color: #ed1c24;
  position: absolute;
  left: 0;
}
.postContent h1, .newsList__description h1, .aboutBi__text h1, .aboutPhilosophy__text h1, .aboutHistory__text h1, .jobsSection__text h1, .project__content h1, .blockContent__text h1, .categoryIntro__text h1, .homeAbout__text h1 {
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}
.postContent h2, .newsList__description h2, .aboutBi__text h2, .aboutPhilosophy__text h2, .aboutHistory__text h2, .jobsSection__text h2, .project__content h2, .blockContent__text h2, .categoryIntro__text h2, .homeAbout__text h2 {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}
.postContent h3, .newsList__description h3, .aboutBi__text h3, .aboutPhilosophy__text h3, .aboutHistory__text h3, .jobsSection__text h3, .project__content h3, .blockContent__text h3, .categoryIntro__text h3, .homeAbout__text h3 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ed1c24;
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}
.postContent figure, .newsList__description figure, .aboutBi__text figure, .aboutPhilosophy__text figure, .aboutHistory__text figure, .jobsSection__text figure, .project__content figure, .blockContent__text figure, .categoryIntro__text figure, .homeAbout__text figure {
  margin: 1.5rem 0;
}
.postContent figure img, .newsList__description figure img, .aboutBi__text figure img, .aboutPhilosophy__text figure img, .aboutHistory__text figure img, .jobsSection__text figure img, .project__content figure img, .blockContent__text figure img, .categoryIntro__text figure img, .homeAbout__text figure img {
  width: 100%;
  height: auto;
}
.postContent blockquote, .newsList__description blockquote, .aboutBi__text blockquote, .aboutPhilosophy__text blockquote, .aboutHistory__text blockquote, .jobsSection__text blockquote, .project__content blockquote, .blockContent__text blockquote, .categoryIntro__text blockquote, .homeAbout__text blockquote {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  color: #7d7b7b;
  max-width: 34.0625rem;
  margin: 2.625rem auto;
}
.postContent blockquote:before, .newsList__description blockquote:before, .aboutBi__text blockquote:before, .aboutPhilosophy__text blockquote:before, .aboutHistory__text blockquote:before, .jobsSection__text blockquote:before, .project__content blockquote:before, .blockContent__text blockquote:before, .categoryIntro__text blockquote:before, .homeAbout__text blockquote:before {
  content: "«";
}
.postContent blockquote:after, .newsList__description blockquote:after, .aboutBi__text blockquote:after, .aboutPhilosophy__text blockquote:after, .aboutHistory__text blockquote:after, .jobsSection__text blockquote:after, .project__content blockquote:after, .blockContent__text blockquote:after, .categoryIntro__text blockquote:after, .homeAbout__text blockquote:after {
  content: "»";
}
.postContent cite, .newsList__description cite, .aboutBi__text cite, .aboutPhilosophy__text cite, .aboutHistory__text cite, .jobsSection__text cite, .project__content cite, .blockContent__text cite, .categoryIntro__text cite, .homeAbout__text cite {
  text-align: center;
  color: #7d7b7b;
  margin-top: -1.6875rem;
  margin-bottom: 2.625rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  letter-spacing: 0.05em;
}

.categoryIntro__title, .homeAbout__title {
  color: #2d2a2a;
  font-size: 1.875rem;
  line-height: 2.5rem;
  position: relative;
  padding-top: 1.875rem;
  margin-bottom: 2.125rem;
}
.categoryIntro__title strong, .homeAbout__title strong {
  font-weight: 700;
}
.categoryIntro__title:before, .homeAbout__title:before {
  content: "";
  width: 2.3125rem;
  height: 0.125rem;
  background-color: #e43535;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 37.5em) {
  .categoryIntro__title, .homeAbout__title {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
    margin-bottom: 1.5625rem;
    padding-top: 0;
  }
  .categoryIntro__title:before, .homeAbout__title:before {
    display: none;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?oqrs85");
  src: url("fonts/icomoon.eot?oqrs85#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?oqrs85") format("truetype"), url("fonts/icomoon.woff?oqrs85") format("woff"), url("fonts/icomoon.svg?oqrs85#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

html {
  height: inherit;
  min-height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: inherit;
  min-height: 100%;
  font-family: "Hind", sans-serif;
}
body.menuOpen {
  overflow: hidden;
  height: 100VH;
}

.wrapper, .banner--category .banner__content {
  position: relative;
  margin: 0 auto;
  max-width: 66.625rem;
}
@media screen and (max-width: 71.625em) {
  .wrapper, .banner--category .banner__content {
    margin: 0;
  }
}

.header {
  box-shadow: 0 0 1.8125rem rgba(0, 0, 0, 0.61);
  position: relative;
  z-index: 3;
}
.header .wrapper, .header .banner--category .banner__content, .banner--category .header .banner__content {
  max-width: 71.625rem;
}
.header__main {
  background-color: #fff;
  z-index: 1;
  position: relative;
}
.header__inside {
  padding-top: 1rem;
  padding-bottom: 0.6875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  width: 19.2307692308%;
}
.header__logoImage {
  max-width: 10.5625rem;
  width: 100%;
  display: block;
  height: auto;
}
.header__side {
  display: flex;
  flex-direction: column-reverse;
}
.header__top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  position: relative;
}
.header .socialMenu {
  margin-left: 1.875rem;
}
.header__immo {
  margin-left: 1.875rem;
}
.header__phone {
  font-size: 0.875rem;
  margin-left: 1.875rem;
  color: #221e1f;
  text-decoration: none;
  display: flex;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.header__phone:hover, .header__phone:focus {
  color: #ed1c24;
}
.header__phone:before {
  color: #e43535;
  font-size: 0.75rem;
  margin-right: 0.375rem;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
}
.header__gohome {
  text-indent: -9999px;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  background-color: #d33535;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.9375rem;
  transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.header__gohome:hover, .header__gohome:focus {
  background-color: #af2626;
}
.header__gohome:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -0.55em;
  left: 0;
  right: 0;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 1.0625rem;
}
.header__slogan {
  font-size: 1.125rem;
  padding-left: 2.625rem;
  color: #221e20;
  position: absolute;
  left: 0;
  vertical-align: middle;
}
.header__slogan em {
  font-weight: 700;
  color: #d33535;
}
.header__searchMobileButton {
  display: none;
}
.header__search {
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 200ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translate3d(0, -100%, 0);
  opacity: 0.5;
  background-color: rgba(34, 31, 31, 0.9);
  padding: 0.75rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  visibility: hidden;
}
.header__search label {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.header__search--active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}
.header__searchForm {
  margin: 0 7.6923076923%;
  display: flex;
}
.header__searchInput {
  outline: none;
  -webkit-appearance: none;
  width: calc(100% - 90px);
  font-family: "Hind", sans-serif;
  border: none;
  padding: 0.875rem 0.8125rem 0.6875rem 0.8125rem;
  font-size: 0.75rem;
  line-height: 1.375rem;
  border-radius: 5px 0 0 5px;
  margin: 0;
}
.header__searchSubmit {
  position: relative;
  width: 5.625rem;
  border: none;
  background-color: #d33535;
  padding: 0;
  border-radius: 0 5px 5px 0;
  outline: none;
  -webkit-appearance: none;
  text-indent: -9999px;
}
.header__searchSubmit:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -0.5em;
  text-align: center;
  text-indent: 0;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.9375rem;
  color: #fff;
}
.header__searchSubmit:hover, .header__searchSubmit:focus {
  background-color: #af2626;
}
@media screen and (max-width: 75em) {
  .header .wrapper, .header .banner--category .banner__content, .banner--category .header .banner__content {
    margin: 0 4.1666666667%;
  }
  .header__logo {
    width: inherit;
  }
  .header__logoImage {
    max-width: 4.90625rem;
  }
  .header__inside {
    padding: 0;
    height: 3.125rem;
  }
  .header__side {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .header__searchForm {
    padding: 2.1875rem 0;
  }
}
@media screen and (max-width: 75em) {
  .header__searchForm {
    flex-direction: column;
  }
  .header__searchInput {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }
  .header__searchSubmit {
    height: 2.9375rem;
    width: 100%;
    border-radius: 0 0 5px 5px;
  }
}

.searchMobileButton {
  display: none;
}
@media screen and (max-width: 75em) {
  .searchMobileButton {
    display: block;
    position: absolute;
    right: 3.125rem;
    top: 0;
    width: 3.125rem;
    height: 3.125rem;
    margin: 0;
    border-left: 1px solid rgba(171, 171, 171, 0.23);
    border-right: 1px solid rgba(171, 171, 171, 0.23);
    text-align: center;
    z-index: 99;
    text-indent: -9999px;
    color: #2d2a2a;
    text-decoration: none;
  }
  .searchMobileButton:before {
    font-family: "icomoon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    font-size: 1.03125rem;
    text-indent: 0;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -0.5em;
  }
}

.footer {
  background-color: #2d2a2a;
  padding-top: 4.25rem;
  padding-bottom: 2.875rem;
  padding-left: 8.3333333333%;
}
.footer__top {
  display: flex;
}
.footer__title {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: #e43535;
  text-transform: uppercase;
  margin-bottom: 2.1875rem;
}
.footer__text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #fff;
  margin-bottom: 1.625rem;
}
.footer__logo {
  width: 14.5833333333%;
}
.footer__logoItem {
  display: block;
}
.footer__logoItem img {
  display: block;
}
.footer__logoItem + .footer__logoItem {
  margin-top: 2.6875rem;
}
.footer__contact {
  margin-left: 4.1666666667%;
  width: 18.75%;
}
.footer__navigation {
  margin-left: 4.1666666667%;
  width: 16.6666666667%;
}
.footer__newsletter {
  margin-left: 4.1666666667%;
  width: 25%;
}
.footer__newsletterInput {
  background-color: #252424;
  border: 1px solid #595959;
  -webkit-appearance: none;
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
  line-height: 1.375rem;
  padding: 0.875rem 0.9375rem;
  border-radius: 0.3125rem;
  width: 100%;
  outline: none;
  margin-bottom: 1.0625rem;
  color: #fff;
}
.footer__newsletterSubmit {
  width: 100%;
  font-family: "Hind", sans-serif;
}
.footer__newsletterSubmit:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  vertical-align: middle;
  margin-right: 0.875rem;
  font-size: 0.875rem;
  position: relative;
  top: -1px;
}
.footer__bottom {
  margin-top: 3.75rem;
  display: flex;
}
.footer__year {
  color: white;
  opacity: 0.5;
  font-weight: 600;
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-right: 3.1875rem;
}
@media screen and (max-width: 75em) {
  .footer {
    padding-left: 4.1666666667%;
  }
  .footer__contact {
    width: 22.9166666667%;
  }
  .footer__navigation {
    width: 16.6666666667%;
  }
  .footer__newsletter {
    width: 29.1666666667%;
  }
}
@media screen and (max-width: 62.5em) {
  .footer__logo {
    width: 100%;
    margin-bottom: 1.875rem;
  }
  .footer__logoItem:nth-child(2) {
    display: none;
  }
  .footer__logoItem img {
    max-width: 9rem;
  }
  .footer__top {
    flex-wrap: wrap;
  }
  .footer__contact {
    margin-left: 0;
    width: 33.3333333333%;
  }
  .footer__newsletter {
    width: 37.5%;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 50em) {
  .footer__newsletterSubmit:before {
    display: none;
  }
}
@media screen and (max-width: 37.5em) {
  .footer__contact {
    position: relative;
  }
  .footer__navigation, .footer__contact, .footer__newsletter {
    margin-left: 0;
    width: 91.6666666667%;
    margin-top: 2.5rem;
  }
  .footer__navigation {
    display: none;
  }
  .footer__bottom {
    display: block;
    text-align: center;
    width: 91.6666666667%;
    margin-top: 2.5rem;
  }
  .footer__year {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
}

.legalMenu {
  color: white;
  font-weight: 600;
  font-size: 0.625rem;
  text-transform: uppercase;
  display: flex;
}
.legalMenu li + li {
  margin-left: 0.625rem;
}
.legalMenu li + li:before {
  content: "|";
  margin-right: 0.625rem;
}
.legalMenu a {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 37.5em) {
  .legalMenu {
    flex-direction: column;
  }
  .legalMenu li + li {
    margin-left: 0;
    margin-top: 1.25rem;
  }
  .legalMenu li + li:before {
    display: none;
  }
}

.contactItem {
  padding-left: 1.6875rem;
  display: block;
  position: relative;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #fff;
  text-decoration: none;
}
.contactItem:before {
  color: #e43535;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0.25rem;
}
.contactItem--mail:before {
  font-size: 0.5rem;
  content: "";
}
.contactItem--phone:before {
  font-size: 0.625rem;
  content: "";
}
.contactItem--address:before {
  font-size: 0.625rem;
  content: "";
}
.contactItem + .contactItem {
  margin-top: 1.0625rem;
}

.mainMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.23);
}
.mainMenu > li {
  position: relative;
}
.mainMenu > li > a {
  padding: 0.5625rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #231f20;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
}
.mainMenu > li > a:after {
  opacity: 0;
  content: "";
  position: absolute;
  bottom: -0.4375rem;
  left: 0.5625rem;
  right: 0.5625rem;
  height: 0.4375rem;
  background-color: #ed1c24;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.current-menu-item .mainMenu > li > a:after, .mainMenu > li > a.active:after, .mainMenu > li > a:hover:after, .mainMenu > li > a:focus:after {
  opacity: 1;
}
.mainMenu > li.current-menu-item > a:after {
  opacity: 1;
}
.mainMenu > li:first-child > a {
  padding-left: 0;
}
.mainMenu > li:first-child > a:after {
  left: 0;
}
.mainMenu > li.search {
  margin-right: -0.25rem;
  margin-left: 0.625rem;
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  text-align: center;
}
.mainMenu > li.search a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -9999px;
  padding: 0;
  font-weight: normal;
}
.mainMenu > li.search a:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  font-size: 0.9375rem;
  text-indent: 0;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -0.5em;
}
.mainMenu > li.search a:after {
  display: none;
}
.mainMenu li a.dropdown {
  position: relative;
}
.mainMenu li ul {
  position: absolute;
  z-index: 1;
  top: 2.125rem;
  padding: 0.6875rem;
  left: 0.5625rem;
  min-width: 18.75rem;
  background-color: rgba(34, 31, 31, 0.9);
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
  visibility: hidden;
  opacity: 0;
}
.mainMenu li ul a {
  opacity: 0.5;
  color: white;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
  padding: 0.625rem;
  display: block;
}
.mainMenu li ul a:hover, .mainMenu li ul a:focus {
  opacity: 1;
}
.mainMenu li ul li + li {
  margin-top: 0.0625rem;
}
.mainMenu li ul:hover,
.mainMenu li ul:focus,
.mainMenu li a.dropdown:hover + ul,
.mainMenu li a.dropdown:focus + ul {
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}

.socialMenu {
  display: flex;
}
.socialMenu__item {
  text-indent: -9999px;
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
}
.socialMenu__item:before {
  text-indent: 0;
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  color: #221e1f;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.socialMenu__item--facebook:before {
  content: "";
}
.socialMenu__item--facebook:hover:before, .socialMenu__item--facebook:focus:before {
  color: #4267B2;
}
.socialMenu__item--twitter:before {
  content: "";
}
.socialMenu__item--twitter:hover:before, .socialMenu__item--twitter:focus:before {
  color: #1DA1F2;
}
.socialMenu__item--instagram:before {
  content: "";
}
.socialMenu__item--instagram:hover:before, .socialMenu__item--instagram:focus:before {
  color: #405DE6;
}
.socialMenu__item--youtube:before {
  content: "";
}
.socialMenu__item--youtube:hover:before, .socialMenu__item--youtube:focus:before {
  color: red;
}
.socialMenu__item--linkedin:before {
  content: "";
}
.socialMenu__item--linkedin:hover:before, .socialMenu__item--linkedin:focus:before {
  color: #2867B2;
}
.socialMenu--contact .socialMenu__item:before {
  font-size: 0.875rem;
  color: #2d2a2a;
}
.socialMenu--footer {
  margin-top: 2.4375rem;
  border-top: 1px solid rgba(130, 122, 122, 0.5);
  padding-top: 2rem;
}
.socialMenu--footer .socialMenu__item:before {
  font-size: 1.0625rem;
  color: #bebebe;
}
.socialMenu--footer .socialMenu__item:hover:before, .socialMenu--footer .socialMenu__item:focus:before {
  color: #fff;
}
@media screen and (max-width: 37.5em) {
  .socialMenu--footer {
    flex-direction: column;
    border-left: 1px solid rgba(130, 122, 122, 0.5);
    border-top: none;
    width: 16.6666666667%;
    position: absolute;
    right: 4.1666666667%;
    top: 0;
    padding-top: 0;
    padding-left: 4.1666666667%;
  }
}
.socialMenu--mobile .socialMenu__item:before {
  font-size: 0.75rem;
  color: #fff;
}
@media screen and (min-width: 37.5625em) {
  .socialMenu--mobile .socialMenu__item:before {
    font-size: 1.0625rem;
  }
}

.footerMenu li {
  display: block;
}
.footerMenu li + li {
  margin-top: 1rem;
}
.footerMenu a {
  font-size: 0.75rem;
  color: #aeaeae;
  text-transform: uppercase;
  text-decoration: none;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.footerMenu a:hover, .footerMenu a:focus {
  color: #fff;
}

.slider {
  height: 50.5124450952vw;
  height: 67vh;
  position: relative;
  overflow: hidden;
  background-color: black;
}
@media screen and (max-width: 62.5em) {
  .slider {
    height: 50.5124450952vw;
  }
}
@media screen and (max-width: 37.5em) {
  .slider {
    height: 20.625rem;
  }
}

.sliderItem {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.js .sliderItem {
  opacity: 0;
  visibility: hidden;
  transition: 600ms opacity linear, 0ms visibility linear 800ms;
}
.js .sliderItem .sliderItem__image {
  transform-origin: center bottom;
  transform: scale(1.035);
  transition: 600ms transform cubic-bezier(0.215, 0.61, 0.355, 1);
}
.js .sliderItem--active {
  opacity: 1;
  visibility: visible;
  transition: 600ms opacity linear, 0ms visibility linear 0ms;
}
.js .sliderItem--active .sliderItem__image {
  transform: scale(1);
}
.sliderItem__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.sliderItem__image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 27, 27, 0.2);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.17), rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.49));
}
.sliderItem__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sliderItem .wrapper, .sliderItem .banner--category .banner__content, .banner--category .sliderItem .banner__content {
  height: 100%;
}
.sliderItem__inside {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 4.1666666667% 7.75rem 4.1666666667%;
}
.sliderItem__subtitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  color: #fff;
}
.sliderItem__title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: #fff;
  padding-top: 1.3125rem;
  margin-top: 1.3125rem;
  margin-bottom: 1.6875rem;
  position: relative;
}
.sliderItem__title:before {
  content: "";
  width: 2.3125rem;
  height: 0.125rem;
  background-color: #e43535;
  position: absolute;
  top: -0.0625rem;
  left: 0;
}
@media screen and (max-width: 62.5em) {
  .sliderItem__inside {
    padding-left: 4.1666666667%;
    padding-right: 4.1666666667%;
    padding-bottom: 2.5rem;
  }
  .sliderItem__title {
    font-size: 1.75rem;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }
}
@media screen and (max-width: 37.5em) {
  .sliderItem__inside {
    text-align: center;
    padding-bottom: 5rem;
  }
  .sliderItem__subtitle {
    font-size: 0.5625rem;
  }
  .sliderItem__title {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0.9375rem;
  }
  .sliderItem__title:before {
    width: 1.25rem;
    height: 0.125rem;
    left: 50%;
    margin-left: -0.625rem;
  }
}

.sliderBullet {
  position: absolute;
  bottom: 7.75rem;
  right: calc(50vw - 978px/2);
  display: flex;
  z-index: 3;
}
@media screen and (max-width: 64em) {
  .sliderBullet {
    right: 5rem;
  }
}
@media screen and (max-width: 62.5em) {
  .sliderBullet {
    right: 2.5rem;
    bottom: 2.5rem;
  }
}
@media screen and (max-width: 37.5em) {
  .sliderBullet {
    bottom: 3.125rem;
    right: 50%;
    transform: translate3d(50%, 0, 0) scale(0.5);
  }
}
.sliderBullet__item {
  text-indent: -9999px;
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
}
.sliderBullet__item + .sliderBullet__item {
  margin-left: 8px;
}
.sliderBullet__svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform-origin: center;
  mix-blend-mode: difference;
}
.sliderBullet__fix {
  fill: none;
  stroke: rgba(67, 74, 82, 0.3);
  stroke-width: 2;
}
.sliderBullet__animated {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-dasharray: 0, 57;
  stroke-linecap: round;
  opacity: 0;
  transition: stroke-dasharray 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sliderBullet__active {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sliderBullet__item--animated .sliderBullet__animated {
  opacity: 1;
  stroke-dasharray: 57, 57;
  transition: stroke-dasharray 4000ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 10ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sliderBullet__item--active .sliderBullet__active {
  opacity: 1;
}

.homeCategories__inside {
  margin-top: -3.375rem;
  padding: 0 4.1666666667%;
  display: flex;
  justify-content: center;
}
.homeCategories__item {
  width: 33.3333%;
  height: 0;
  padding-top: 20.1219310976%;
  position: relative;
}
.homeCategories__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.homeCategories__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeCategories__image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 27, 27, 0.15);
}
.homeCategories__text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.75rem 1.5rem;
  font-weight: 500;
  z-index: 1;
  background-color: transparent;
  transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.homeCategories__text:after {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  right: 1.5rem;
  bottom: 1.75rem;
  color: #fff;
  opacity: 0;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.homeCategories__title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 1.875rem;
}
.homeCategories__title:after {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.5625rem;
  margin-left: 0.8125rem;
}
.homeCategories__description {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.homeCategories__link {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -9999px;
}
.homeCategories__link:hover + .homeCategories__text, .homeCategories__link:focus + .homeCategories__text {
  background-color: #d33535;
}
.homeCategories__link:hover + .homeCategories__text:after,
.homeCategories__link:hover + .homeCategories__text .homeCategories__description, .homeCategories__link:focus + .homeCategories__text:after,
.homeCategories__link:focus + .homeCategories__text .homeCategories__description {
  opacity: 1;
  transition-delay: 100ms;
}
@media screen and (max-width: 62.5em) {
  .homeCategories .wrapper, .homeCategories .banner--category .banner__content, .banner--category .homeCategories .banner__content {
    margin: 0;
  }
  .homeCategories__inside {
    margin-top: 0;
    padding: 0 0;
  }
}
@media screen and (max-width: 37.5em) {
  .homeCategories__inside {
    margin-top: -1.25rem;
    padding: 0 8.3333333333%;
    flex-direction: column;
  }
  .homeCategories__item {
    width: 100%;
    padding-top: 61.9047619048%;
  }
}

.homeAbout {
  margin-top: 7.8125rem;
  margin-bottom: 10.9375rem;
}
.homeAbout__inside {
  padding: 0 8.3333333333%;
  display: flex;
  align-items: center;
}
.homeAbout__image {
  width: 30%;
  margin-right: 20%;
  box-shadow: 0.875rem 0.875rem 0 #edecec;
}
.homeAbout__image img {
  display: block;
  width: 100%;
  height: auto;
}
.homeAbout__content {
  width: 50%;
}
.homeAbout__text {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 71.625em) {
  .homeAbout {
    margin-top: 4.375rem;
    margin-bottom: 5.625rem;
  }
  .homeAbout__inside {
    padding: 0 4.1666666667%;
  }
}
@media screen and (max-width: 37.5rem) {
  .homeAbout__inside {
    flex-direction: column;
    padding: 0 8.3333333333%;
  }
  .homeAbout__image {
    width: 100%;
    margin-right: 0;
  }
  .homeAbout__content {
    width: 100%;
  }
}

.featuredProject {
  display: flex;
  height: 21.875rem;
  margin-bottom: 9.375rem;
  overflow: hidden;
}
@media screen and (max-width: 62.5em) {
  .featuredProject {
    flex-direction: column;
    height: inherit;
    margin-bottom: 5.625rem;
  }
}
@media screen and (max-width: 37.5em) {
  .featuredProject {
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%;
  }
}
.featuredProject__header {
  width: 25%;
  padding: 3.75rem;
  background-color: #2d2a2a;
}
.featuredProject__content + .featuredProject__content {
  margin-top: 1.875rem;
  padding-top: 1.875rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.featuredProject__title {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  font-weight: 600;
}
.featuredProject__title:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.4375rem;
  color: #e43535;
  vertical-align: middle;
  margin-right: 0.9375rem;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: 200ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: inline-block;
}
.featuredProject__content--active .featuredProject__title:before {
  transform: rotate(0deg);
}
.featuredProject__text {
  color: #cfcece;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  max-width: 13.125rem;
  max-height: 0rem;
  overflow: hidden;
  opacity: 0;
  transition: 200ms max-height cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms, 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.featuredProject__content--active .featuredProject__text {
  max-height: 12.5rem;
  opacity: 1;
  transition: 200ms max-height cubic-bezier(0.25, 0.46, 0.45, 0.94), 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms;
}
.no-js .featuredProject__text {
  opacity: 1;
  max-height: 18.75rem;
}
.featuredProject__textInside {
  padding-top: 1.6875rem;
}
.featuredProject__sectionList {
  width: 75%;
  position: relative;
}
.featuredProject__section {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: 200ms opacity cubic-bezier(0.55, 0.085, 0.68, 0.53), 0ms visibility linear 200ms;
}
.featuredProject__section--active {
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}
@media screen and (max-width: 75em) {
  .featuredProject {
    flex-direction: column;
    height: inherit;
  }
  .featuredProject__header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: inherit;
    padding: 0;
  }
  .featuredProject__text {
    display: none;
  }
  .featuredProject__sectionList {
    width: 100%;
    height: 21.875rem;
  }
  .featuredProject__content {
    width: 50%;
    background-color: rgba(45, 42, 42, 0.9);
    transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .featuredProject__content + .featuredProject__content {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  .featuredProject__content--active {
    background-color: #2d2a2a;
    transform: rotate(0deg);
  }
  .featuredProject__content--active .featuredProject__title {
    color: white;
  }
  .featuredProject__content--active .featuredProject__title:before {
    transform: rotate(0deg);
    opacity: 1;
  }
  .featuredProject__title {
    text-align: center;
    padding: 1.875rem;
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .featuredProject__title:before {
    position: absolute;
    bottom: 0.9375rem;
    transform: rotate(0deg);
    left: 0;
    right: 0;
    margin-right: 0;
    opacity: 0;
    transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
@media screen and (max-width: 37.5em) {
  .featuredProject__sectionList {
    height: 65.625rem;
  }
  .featuredProject__section {
    flex-direction: column;
  }
}

.cardProject {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  position: relative;
  width: 33.3333%;
}
.cardProject__text {
  padding: 1.8125rem;
  color: #fff;
  text-align: center;
  transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cardProject__title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
}
.cardProject__category {
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.cardProject__image {
  position: relative;
}
.cardProject__image img {
  width: 100%;
  height: auto;
  display: block;
}
.cardProject__image:after {
  content: "";
  background-color: rgba(33, 31, 31, 0.68);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cardProject__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.cardProject__link:hover .cardProject__linkLabel, .cardProject__link:focus .cardProject__linkLabel {
  opacity: 1;
}
.cardProject__link:hover + .cardProject__text + .cardProject__image:after, .cardProject__link:focus + .cardProject__text + .cardProject__image:after {
  opacity: 1;
}
.cardProject__linkLabel {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  top: 7.8125rem;
  transform: translateY(-50%);
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  opacity: 0;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cardProject__linkLabel:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  display: block;
  font-size: 1rem;
  margin-bottom: 1.25rem;
}
.cardProject--projectList .cardProject__title {
  margin-bottom: 0.25rem;
}
.cardProject--projectList .cardProject__text {
  background-color: rgba(57, 56, 57, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 1.25rem;
}
.cardProject--projectList .cardProject__link:hover + .cardProject__text, .cardProject--projectList .cardProject__link:focus + .cardProject__text {
  background-color: #c44040;
}
.cardProject--projectList .cardProject__linkLabel {
  font-size: 0.625rem;
  top: 5.78125rem;
}
.cardProject--projectList .cardProject__linkLabel:before {
  margin-bottom: 0.5rem;
}
.cardProject--featuredProject {
  width: 33.3333%;
  height: 100%;
}
.cardProject--featuredProject .cardProject__link:hover + .cardProject__text, .cardProject--featuredProject .cardProject__link:focus + .cardProject__text {
  background-color: #c44040;
}
.cardProject--featuredProject .cardProject__image img {
  height: 15.625rem;
  object-fit: cover;
}
.cardProject--featuredProject .cardProject__text {
  background-color: #e43535;
}
@media screen and (max-width: 37.5em) {
  .cardProject--featuredProject {
    width: 100%;
    height: 21.875rem;
  }
}

.homeTrust__inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12.5%;
  margin-bottom: 7.8125rem;
}
.homeTrust__content {
  position: relative;
  width: 40%;
  text-align: center;
}
.homeTrust__title {
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: #2d2a2a;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
}
.homeTrust__title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -0.875rem;
  width: 1.75rem;
  height: 0.125rem;
  background-color: #e43535;
}
.homeTrust__text {
  color: #828282;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 500;
}
.homeTrust__slider {
  margin-top: 3.75rem;
  width: 100%;
  height: 17.5rem;
  position: relative;
}
.homeTrust__slide {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}
.js .homeTrust__slide {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  bottom: inherit;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
}
.js .homeTrust__slide--active {
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}
.homeTrust__client {
  display: block;
  width: 25%;
  height: 8.75rem;
  border-right: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeTrust__client:nth-child(1), .homeTrust__client:nth-child(2), .homeTrust__client:nth-child(3), .homeTrust__client:nth-child(4) {
  border-bottom: 1px solid #f0f0f0;
}
.homeTrust__client:nth-child(4), .homeTrust__client:nth-child(8) {
  border-right: none;
}
.homeTrust__client img {
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.homeTrust__sliderBullet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.8125rem;
  display: flex;
  justify-content: center;
}
.homeTrust__sliderBulletItem {
  text-indent: -9999px;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
}
.homeTrust__sliderBulletItem:before {
  content: "";
  text-indent: 0;
  top: 0.4375rem;
  left: 0.4375rem;
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.375rem;
  background-color: #b6b5b5;
}
.homeTrust__sliderBulletItem:hover:before, .homeTrust__sliderBulletItem:focus:before, .homeTrust__sliderBulletItem--active:before {
  background-color: #e43535;
}
@media screen and (max-width: 50em) {
  .homeTrust__client img {
    max-width: 80%;
  }
}
@media screen and (max-width: 37.5em) {
  .homeTrust__content {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .homeTrust__slider {
    height: 35rem;
  }
  .homeTrust__client {
    width: 50%;
    border: none !important;
  }
}

.banner {
  height: 21.25rem;
  position: relative;
  background-color: #211f1f;
}
.banner__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.banner__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner__image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.71), transparent);
}
.banner__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
}
.banner__subtitle {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: #e43535;
  text-transform: uppercase;
}
.banner__title {
  font-size: 1.875rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.19);
}
.banner__menu {
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  margin-top: -2.625rem;
  background-color: rgba(237, 28, 36, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner__menuItem {
  padding: 0.9375rem 1.5rem;
  font-size: 0.75rem;
  letter-spacing: pl_ls(50);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.banner__menuItem--immo {
  background-color: rgba(149, 46, 49, 0.8);
}
.banner--category .banner__content {
  position: absolute;
  left: calc(50% - 489px);
  bottom: 3.9375rem;
  top: inherit;
  transform: none;
}
.banner--thinner {
  height: 9.375rem;
}
@media screen and (max-width: 62.5em) {
  .banner {
    height: 17.5rem;
  }
  .banner__content {
    width: 80%;
  }
  .banner--category .banner__content {
    left: 4.1666666667%;
    position: absolute;
    bottom: 2.5rem;
    top: inherit;
    max-width: inherit;
    width: inherit;
  }
}
@media screen and (max-width: 37.5em) {
  .banner {
    height: 13.75rem;
  }
  .banner__title {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  .banner__title br {
    display: none;
  }
  .banner__menu {
    display: none;
  }
}

.bannerArticle {
  min-height: 15.25rem;
  background-color: #2d2a2a;
  padding: 3.3125rem 8.3333333333% 2.8125rem 8.3333333333%;
}
.bannerArticle__inside {
  max-width: 44.375rem;
  margin: 0 auto;
}
.bannerArticle__all {
  margin-bottom: 3.625rem;
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}
.bannerArticle__all:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.625rem;
  color: #e43535;
  padding-right: 0.875rem;
  vertical-align: middle;
}
.bannerArticle__date {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #e43535;
  margin-bottom: 1rem;
  display: block;
  text-transform: uppercase;
}
.bannerArticle__title {
  width: 81.25%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #fff;
  font-weight: 700;
}
@media screen and (max-width: 64em) {
  .bannerArticle {
    min-height: 0;
  }
  .bannerArticle__all {
    display: none;
  }
  .bannerArticle__title {
    width: 100%;
  }
}

.imageArticle {
  position: relative;
  margin-top: -0.0625rem;
  padding: 0 8.3333333333% 0 8.3333333333%;
}
.imageArticle:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 6.25rem;
  background-color: #2d2a2a;
}
.imageArticle__figure {
  max-width: 44.375rem;
  margin: 0 auto;
}
.imageArticle__figure img {
  width: 100%;
  height: auto;
  display: block;
}

.articleFooter {
  margin-top: 6.25rem;
  height: 4.375rem;
  position: relative;
  background-color: #f4f4f4;
}
.articleFooter__button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.categoryIntro {
  margin-top: 6.25rem;
  margin-bottom: 4.375rem;
  padding: 0 4.1666666667%;
  display: flex;
  align-items: center;
}
.categoryIntro__image {
  width: 31.8181818182%;
  margin-right: 18.1818181818%;
  box-shadow: 0.875rem 0.875rem 0 #edecec;
}
.categoryIntro__image img {
  display: block;
  width: 100%;
  height: auto;
}
.categoryIntro__content {
  width: 45.4545454545%;
}
@media screen and (max-width: 50em) {
  .categoryIntro {
    margin-top: 4.375rem;
    margin-bottom: 3.125rem;
  }
  .categoryIntro__image {
    width: 40.9090909091%;
    margin-right: 9.0909090909%;
    margin-left: -4.5454545455%;
    box-shadow: none;
  }
  .categoryIntro__image img {
    display: block;
    width: 100%;
    height: auto;
  }
  .categoryIntro__content {
    width: 54.5454545455%;
  }
}
@media screen and (max-width: 37.5em) {
  .categoryIntro {
    margin-top: 3.125rem;
    flex-direction: column-reverse;
  }
  .categoryIntro__content {
    width: 100%;
  }
  .categoryIntro__image {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    max-width: 19.375rem;
    margin: 0 auto;
    margin-top: 1.5625rem;
  }
}

.blockContent {
  padding: 0 8.3333333333% 0 4.1666666667%;
  margin-bottom: 6.25rem;
  display: flex;
  justify-content: space-between;
}
.blockContent__item {
  width: 42.8571428571%;
}
.blockContent__item:nth-child(2) {
  width: 47.619047619%;
}
.blockContent__title {
  margin-bottom: 2rem;
}
.blockContent__button {
  margin-top: 1.875rem;
}
@media screen and (max-width: 50em) {
  .blockContent {
    flex-direction: column;
    margin-bottom: 4.375rem;
    padding: 0 4.1666666667% 0 4.1666666667%;
  }
  .blockContent__title {
    margin-bottom: 1rem;
  }
  .blockContent__item {
    width: 100%;
  }
  .blockContent__item:nth-child(2) {
    width: 100%;
    margin-top: 3.125rem;
  }
}

.projectList {
  padding: 0 4.1666666667%;
  display: flex;
  margin-bottom: 6.25rem;
  flex-wrap: wrap;
}
@media screen and (max-width: 50em) {
  .projectList {
    margin-bottom: 3.75rem;
  }
  .projectList .cardProject {
    width: 50%;
  }
}
@media screen and (max-width: 37.5em) {
  .projectList .cardProject {
    width: 100%;
  }
}

.projectSlider {
  width: 66.6666666667%;
  margin: 0 auto;
  margin-top: -6.875rem;
  margin-bottom: 5.625rem;
  position: relative;
}
.projectSlider__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projectSlider__prev, .projectSlider__next {
  top: 50%;
  margin-top: -1.1875rem;
  width: 2.375rem;
  height: 2.375rem;
  background-color: #e43535;
  border-radius: 2.375rem;
  text-indent: -9999px;
  text-decoration: none;
  position: absolute;
  transition: 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.projectSlider__prev:before, .projectSlider__next:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  text-indent: 0;
  font-size: 0.5625rem;
}
.projectSlider__prev:hover, .projectSlider__prev:focus, .projectSlider__next:hover, .projectSlider__next:focus {
  background-color: #cb1b1b;
}
.projectSlider__prev {
  left: -4.875rem;
}
.projectSlider__prev:before {
  content: "";
}
.projectSlider__next {
  right: -4.875rem;
}
.projectSlider__next:before {
  content: "";
}
.projectSlider__bulletList {
  display: none;
  position: absolute;
}
.projectSlider__bulletList {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.875rem;
  display: flex;
  justify-content: center;
}
.projectSlider__bullet {
  text-indent: -9999px;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
}
.projectSlider__bullet:before {
  content: "";
  text-indent: 0;
  top: 0.4375rem;
  left: 0.4375rem;
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.375rem;
  background-color: #b6b5b5;
}
.projectSlider__bullet:hover:before, .projectSlider__bullet--active:before {
  background-color: #e43535;
}
@media screen and (max-width: 64em) {
  .projectSlider {
    margin-top: -5.3125rem;
    width: 75%;
  }
}
@media screen and (max-width: 50em) {
  .projectSlider {
    width: 83.3333333333%;
  }
  .projectSlider__prev {
    left: -1.1875rem;
  }
  .projectSlider__prev:before {
    content: "";
  }
  .projectSlider__next {
    right: -1.1875rem;
  }
  .projectSlider__next:before {
    content: "";
  }
}
@media screen and (max-width: 37.5em) {
  .projectSlider {
    margin-top: -1.875rem;
  }
  .projectSlider__prev, .projectSlider__next {
    display: none;
  }
}

.project {
  padding: 0 16.6666666667%;
  margin-bottom: 5.625rem;
  display: flex;
}
.project__main {
  width: 62.5%;
}
.project__intro {
  width: 90%;
}
.project__invoiceButton {
  margin-top: 1.875rem;
}
.project__featureList {
  margin-left: 6.25%;
  width: 31.25%;
}
.project__featureItem + .project__featureItem {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.project__featureItem:first-child .project__featureTerm,
.project__featureItem:first-child .project__featureData {
  padding-top: 0;
}
.project__featureTerm, .project__featureData {
  text-transform: uppercase;
  font-size: 0.625rem;
  list-style: 0.05em;
  font-weight: 600;
  padding: 0.9375rem 0;
}
.project__featureTerm {
  text-align: left;
  color: #7a7575;
}
.project__featureData {
  color: #e43535;
}
@media screen and (max-width: 64em) {
  .project {
    padding: 0 8.3333333333%;
    margin-bottom: 4.6875rem;
  }
}
@media screen and (max-width: 37.5em) {
  .project {
    padding: 0 8.3333333333%;
    flex-direction: column;
    margin-bottom: 3.125rem;
  }
  .project__main {
    width: 100%;
  }
  .project__intro {
    width: 100%;
  }
  .project__featureList {
    margin-top: 3.125rem;
    margin-left: 0;
    width: 100%;
  }
}

.jobsSection {
  margin-top: 7.5rem;
  margin-bottom: 6.25rem;
  padding: 0 4.1666666667%;
  display: flex;
  align-items: flex-start;
}
.jobsSection--download {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}
.jobsSection__image {
  width: 31.8181818182%;
  margin-right: 18.1818181818%;
  box-shadow: 0.875rem 0.875rem 0 #edecec;
}
.jobsSection__image img {
  display: block;
  width: 100%;
  height: auto;
}
.jobsSection__content {
  width: 45.4545454545%;
}
.jobsSection__section + .jobsSection__section {
  margin-top: 3.75rem;
}
.jobsSection__title {
  margin-bottom: 1.5625rem;
}
.jobsSection__btn {
  margin-top: 1.625rem;
}
.jobsSection__offer {
  margin-top: 3.375rem;
}
.jobsSection .offer {
  display: block;
  padding: 1rem 0;
  text-decoration: none;
  font-weight: 600;
  color: #7a7575;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.jobsSection .offer:after {
  content: "";
  display: block;
  clear: both;
}
.jobsSection .offer:hover, .jobsSection .offer:focus {
  color: #2d2a2a;
}
.jobsSection .offer .offerName {
  float: left;
}
.jobsSection .offer .offerLabel {
  float: right;
  color: #e43535;
  position: relative;
}
.jobsSection .offer .offerLabel:after {
  padding-left: 0.5625rem;
  padding-right: 1.0625rem;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.5625rem;
}
@media screen and (max-width: 50em) {
  .jobsSection {
    margin-top: 5rem;
    margin-bottom: 9.375rem;
  }
  .jobsSection__image {
    display: none;
  }
  .jobsSection__content {
    width: 100%;
  }
}

.testimony {
  background-color: #f4f4f4;
  text-align: center;
}
.testimony__inside {
  padding-top: 7.5rem;
  padding-bottom: 5.625rem;
}
.testimony__image {
  position: absolute;
  top: -4.6875rem;
  left: 50%;
  margin-left: -4.6875rem;
  overflow: hidden;
  border-radius: 50%;
  width: 9.375rem;
  height: 9.375rem;
}
.testimony__image img {
  display: block;
  width: 100%;
  height: auto;
}
.testimony__text {
  color: #2d2a2a;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.testimony__text:before {
  content: "«";
}
.testimony__text:after {
  content: "»";
}
.testimony__footer {
  margin-top: 1.25rem;
  color: #aca8a8;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 37.5em) {
  .testimony__text {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .testimony__inside {
    padding-top: 7.5rem;
    padding-bottom: 3.75rem;
    padding-right: 4.1666666667%;
    padding-left: 4.1666666667%;
  }
  .testimony br {
    display: none;
  }
}

.aboutHistory {
  padding-top: 6.25rem;
  margin-bottom: 4.375rem;
}
.aboutHistory__inside {
  display: flex;
  padding: 0 4.1666666667%;
  align-items: flex-start;
}
.aboutHistory__image {
  width: 31.8181818182%;
  margin-right: 18.1818181818%;
  box-shadow: 0.875rem 0.875rem 0 #edecec;
}
.aboutHistory__image img {
  display: block;
  width: 100%;
  height: auto;
}
.aboutHistory__content {
  width: 45.4545454545%;
}
@media screen and (max-width: 50rem) {
  .aboutHistory {
    padding-top: 4.375rem;
  }
  .aboutHistory__image {
    margin-left: -4.5454545455%;
    margin-right: 9.0909090909%;
    width: 36.3636363636%;
  }
  .aboutHistory__content {
    width: 59.0909090909%;
  }
}
@media screen and (max-width: 37.5rem) {
  .aboutHistory__inside {
    flex-direction: column-reverse;
  }
  .aboutHistory__content {
    width: 100%;
  }
  .aboutHistory__image {
    margin-left: 0;
    width: 100%;
    box-shadow: none;
    margin-top: 3.125rem;
  }
}

.aboutPhilosophy {
  padding-top: 5.3125rem;
  background-color: #2d2a2a;
  color: #fff;
  position: relative;
}
.aboutPhilosophy__inside {
  display: flex;
  align-items: flex-end;
  padding-left: 4.1666666667%;
}
.aboutPhilosophy__content {
  width: 47.8260869565%;
  margin-right: 13.0434782609%;
  padding-bottom: 5.3125rem;
}
.aboutPhilosophy__title {
  margin-bottom: 1.625rem;
}
.aboutPhilosophy__intro {
  color: #fff;
}
.aboutPhilosophy__text {
  color: #fff;
}
.aboutPhilosophy__button {
  margin-top: 1.875rem;
}
.aboutPhilosophy__slider {
  position: relative;
  width: 43.4782608696%;
  width: calc(43.4782608696% + (100vw - 66.625rem)/2 );
  margin-right: calc((100vw - 66.625rem)/-2 );
  height: 20.3125rem;
}
.aboutPhilosophy__slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 600ms opacity linear;
  opacity: 0;
}
.aboutPhilosophy__slide--active {
  opacity: 1;
  z-index: 1;
}
.aboutPhilosophy__slide img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}
.aboutPhilosophy__slideCaption {
  text-align: center;
  position: absolute;
  bottom: -3.75rem;
  left: 0;
  background-color: #e43535;
  width: 100%;
  max-width: 26.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 2.1875rem 4.125rem;
}
.aboutPhilosophy__slideCaption:before {
  content: "« ";
}
.aboutPhilosophy__slideCaption:after {
  content: " »";
}
.aboutPhilosophy__bulletList {
  position: absolute;
  left: -0.4375rem;
  right: 0;
  bottom: -5.625rem;
  display: flex;
}
.aboutPhilosophy__bullet {
  text-indent: -9999px;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
}
.aboutPhilosophy__bullet:before {
  content: "";
  text-indent: 0;
  top: 0.4375rem;
  left: 0.4375rem;
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.375rem;
  background-color: #b6b5b5;
}
.aboutPhilosophy__bullet:hover:before, .aboutPhilosophy__bullet:focus:before, .aboutPhilosophy__bullet--active:before {
  background-color: #e43535;
}
@media screen and (max-width: 71.625em) {
  .aboutPhilosophy__slider {
    margin-right: -2.5rem;
    width: 43.4782608696%;
  }
}
@media screen and (max-width: 50rem) {
  .aboutPhilosophy {
    padding-top: 4.375rem;
  }
  .aboutPhilosophy__content {
    padding-bottom: 4.375rem;
  }
  .aboutPhilosophy__inside {
    flex-direction: column;
    align-items: flex-start;
  }
  .aboutPhilosophy__content {
    width: 69.5652173913%;
    margin-right: 0;
  }
  .aboutPhilosophy__slider {
    position: relative;
    width: 95.652173913%;
    margin-right: 0;
    height: 20.3125rem;
  }
}
@media screen and (max-width: 37.5rem) {
  .aboutPhilosophy__content {
    width: 95.652173913%;
    margin-right: 0;
    padding-bottom: 3.125rem;
  }
  .aboutPhilosophy__slider {
    height: 12.5rem;
  }
  .aboutPhilosophy__slideCaption {
    text-align: center;
    position: absolute;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
  }
  .aboutPhilosophy__bulletList {
    z-index: 3;
    bottom: -8.75rem;
  }
}

.aboutFeature {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.aboutFeature__item {
  width: 33.3333%;
}
.aboutFeature__number {
  font-size: 2rem;
  margin-bottom: 0.3125rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #fff;
}
.aboutFeature__text {
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #e43535;
}
.aboutFeature__text, .aboutFeature__number {
  display: block;
  text-align: center;
}
@media screen and (max-width: 37.5rem) {
  .aboutFeature {
    flex-direction: column;
  }
  .aboutFeature__item {
    width: 100%;
  }
  .aboutFeature__item + .aboutFeature__item {
    margin-top: 2.5rem;
  }
}

.aboutAssets {
  padding-top: 6.25rem;
  background-color: #f4f4f4;
  text-align: center;
  padding-bottom: 6.5625rem;
}
.aboutAssets__title {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: #e43535;
  text-transform: uppercase;
  margin-bottom: 1.5625rem;
}
.aboutAssets__text {
  width: 37.5%;
  margin: 0 auto;
  color: #2d2a2a;
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin-bottom: 5.3125rem;
}
.aboutAssets__list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutAssets__item {
  width: 16.6666666667%;
}
.aboutAssets__image {
  width: 6.625rem;
  height: 6.625rem;
  border-radius: 50%;
  background-color: #2d2a2a;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutAssets__image svg path {
  fill: #fff;
}
@media screen and (max-width: 64rem) {
  .aboutAssets__text {
    width: 66.6666666667%;
  }
}
@media screen and (max-width: 50rem) {
  .aboutAssets__item {
    width: 20.8333333333%;
  }
  .aboutAssets__image {
    width: 5.625rem;
    height: 5.625rem;
  }
}
@media screen and (max-width: 37.5rem) {
  .aboutAssets__list {
    flex-direction: column;
  }
  .aboutAssets__item {
    width: 100%;
  }
  .aboutAssets__item + .aboutAssets__item {
    margin-top: 1.875rem;
  }
  .aboutAssets__text {
    width: 83.3333333333%;
  }
  .aboutAssets__image {
    width: 6.625rem;
    height: 6.625rem;
  }
}
.aboutAssets__label {
  display: block;
  margin-top: 2.5rem;
  text-align: center;
  font-weight: 600;
  color: #2d2a2a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
}

.aboutBi {
  padding-top: 7.5rem;
  margin-bottom: 6.875rem;
}
.aboutBi__inside {
  display: flex;
  padding: 0 4.1666666667%;
  align-items: flex-start;
  padding-top: 3.4375rem;
}
.aboutBi__image {
  width: 31.8181818182%;
  margin-right: 18.1818181818%;
  box-shadow: 0.875rem 0.875rem 0 #edecec;
}
.aboutBi__image img {
  display: block;
  width: 100%;
  height: auto;
}
.aboutBi__content {
  width: 45.4545454545%;
}
.aboutBi__logo {
  margin-bottom: 3.25rem;
}
.aboutBi__logo img {
  max-width: 13.25rem;
  width: 100%;
  height: auto;
}
.aboutBi__button {
  margin-top: 1.375rem;
}
@media screen and (max-width: 50rem) {
  .aboutBi {
    padding-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .aboutBi__image {
    margin-left: -4.5454545455%;
    margin-right: 9.0909090909%;
    width: 36.3636363636%;
  }
  .aboutBi__content {
    width: 59.0909090909%;
  }
}
@media screen and (max-width: 37.5rem) {
  .aboutBi__inside {
    flex-direction: column-reverse;
  }
  .aboutBi__content {
    width: 100%;
    margin-bottom: 3.125rem;
  }
  .aboutBi__image {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    box-shadow: none;
  }
}

.aboutVideo {
  position: relative;
  background-color: #f4f4f4;
  padding-bottom: 6.25rem;
  position: relative;
}
.aboutVideo:before {
  content: "";
  position: absolute;
  top: -1px;
  height: 8.1875rem;
  left: 0;
  right: 0;
  background-color: #fff;
}
.aboutVideo__sizer {
  margin: 0 auto;
  background-color: #000;
  width: 58.3333333333%;
  padding-bottom: 32.8125%;
  height: 0;
  position: relative;
}
.aboutVideo__sizer iframe {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.aboutVideo__playButton {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #000;
  text-decoration: none;
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}
.aboutVideo__playButton:before {
  z-index: 2;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -0.5em;
  font-size: 3.6875rem;
  text-align: center;
  color: white;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.aboutVideo__playButton:hover:before, .aboutVideo__playButton:focus:before {
  color: #e43535;
}
.aboutVideo__playButton--unactive {
  opacity: 0;
  visibility: hidden;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
}
.aboutVideo__previewImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.aboutVideo__previewImage img {
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.32;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.aboutVideo__player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 50rem) {
  .aboutVideo__sizer {
    width: 83.3333333333%;
    padding-bottom: 46.875%;
    height: 0;
    position: relative;
  }
  .aboutVideo__sizer iframe {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 37.5rem) {
  .aboutVideo__sizer {
    width: 91.6666666667%;
    padding-bottom: 51.5625%;
    height: 0;
    position: relative;
  }
  .aboutVideo__sizer iframe {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.newsList {
  margin-top: -3.9375rem;
  padding: 0 8.3333333333%;
  margin-bottom: 4.375rem;
}
.newsList__item {
  position: relative;
  display: flex;
  background-color: #f4f4f4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
}
.newsList__item + .newsList__item {
  margin-top: 2.25rem;
}
.newsList__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.newsList__link:hover .newsList__linkInside, .newsList__link:focus .newsList__linkInside {
  opacity: 1;
}
.newsList__linkInside {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35%;
  background-color: rgba(51, 51, 51, 0.72);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.newsList__linkLabel {
  display: block;
  text-align: center;
  font-size: 0.625rem;
  letter-spacing: 0.05em;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}
.newsList__linkLabel:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.newsList__image {
  width: 35%;
}
.newsList__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.newsList__content {
  width: 65%;
  padding: 3.125rem 10%;
}
.newsList__date {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #e43535;
  margin-bottom: 1.375rem;
  display: block;
}
.newsList__title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: 700;
  color: #2d2a2a;
}
.newsList__description {
  margin-top: 1.375rem;
  line-height: 1.375rem;
  color: #2d2a2a;
}
@media screen and (max-width: 75em) {
  .newsList__content {
    padding: 2.5rem 5%;
  }
}
@media screen and (max-width: 62.5em) {
  .newsList__content {
    padding: 2.5rem 5%;
  }
  .newsList__description {
    display: none;
  }
}
@media screen and (max-width: 37.5em) {
  .newsList__item {
    flex-direction: column;
  }
  .newsList__content {
    width: 100%;
    padding: 1.875rem;
  }
  .newsList__linkInside {
    display: none;
  }
  .newsList__description {
    display: block;
  }
  .newsList__image {
    width: 100%;
  }
}

.searchSection {
  background-color: #f4f4f4;
  padding: 4.375rem 8.3333333333% 6.25rem 8.3333333333%;
  margin: -4.375rem auto 5.625rem auto;
  width: 79.1666666667%;
  position: relative;
}
.searchSection__query {
  margin-bottom: 3.125rem;
  font-size: 0.875rem;
}
.searchSection__queryTerm {
  font-weight: 600;
  font-size: 0.875rem;
  color: #d33535;
  text-transform: uppercase;
}
.searchSection__queryData {
  color: #807e7e;
}
.searchSection__item {
  position: relative;
  padding-bottom: 1.4375rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.searchSection__item + .searchSection__item {
  margin-top: 2.125rem;
}
.searchSection__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -9999px;
  display: block;
}
.searchSection__link:hover + .searchSection__title, .searchSection__link:focus + .searchSection__title {
  color: #d33535;
}
.searchSection__link:hover + .searchSection__title + .searchSection__description, .searchSection__link:focus + .searchSection__title + .searchSection__description {
  color: #2d2a2a;
}
.searchSection__title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: #2d2a2a;
  margin-bottom: 1rem;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.searchSection__description {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #8a8a8a;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.searchSection__homeButton {
  position: absolute;
  left: 10.5263157895%;
  bottom: 0;
  transform: translateY(50%);
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
@media screen and (max-width: 37.5em) {
  .searchSection {
    margin: 0 auto;
    width: 100%;
  }
  .searchSection__homeButton {
    position: relative;
    left: 0;
    transform: noner;
  }
}

.gotomenu {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
@media screen and (max-width: 75em) {
  .gotomenu {
    position: absolute;
    left: initial;
    right: 0;
    top: 0;
    width: 3.125rem;
    height: 3.125rem;
    z-index: 100;
  }
}

.burger {
  height: 1.875rem;
  width: 3.125rem;
  display: inline-block;
  z-index: 11;
}
.burger__medium, .burger:before, .burger:after {
  background-color: #2d2a2a;
  position: absolute;
  left: 0.9375rem;
  right: 0.9375rem;
  height: 0.125rem;
  border-radius: 5px;
  transition: 200ms color linear;
}
.burger__medium {
  top: 50%;
  margin-top: -0.09375rem;
  text-indent: -9999px;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: 150ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms, 100ms opacity linear 150ms;
}
.burger:before, .burger:after {
  content: "";
  transition: 150ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
}
.burger:before {
  top: 50%;
  margin-top: -8.5px;
  transform-origin: center center;
}
.burger:after {
  top: 50%;
  margin-top: 5.5px;
  transform-origin: center center;
}
.menuOpen .burger__medium {
  transform: translate3d(-15px, 0, 0);
  opacity: 0;
  transition: 150ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, 150ms opacity linear 0ms;
}
.menuOpen .burger:before {
  transform: translate3d(0, 8.5px, 0) rotate(45deg);
  transition: 150ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms;
}
.menuOpen .burger:after {
  transform: translate3d(0, -5.5px, 0) rotate(-45deg);
  transition: 150ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms;
}

.mobileMenu {
  position: absolute;
  top: -99999px;
  left: -99999px;
  background-color: #2d2a2a;
  opacity: 0;
  visibility: hidden;
  transition: 400ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 400ms;
  padding: 2.8125rem 8.3333333333% 6.5625rem 8.3333333333%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* on laisse le défilement se poursuivre */
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  margin-top: 1px;
  height: calc(100vh - 50px);
}
@media screen and (max-width: 75em) {
  .mobileMenu {
    left: 0;
    right: 0;
    top: 3.125rem;
  }
}
.menuOpen .mobileMenu {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: 400ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}
.mobileMenu__menu .menu-item a {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 0;
  display: block;
}
.mobileMenu__menu .menu-item:hover, .mobileMenu__menu .menu-item:focus, .mobileMenu__menu .menu-item.current-menu-item a {
  color: #e43535;
}
.mobileMenu__menu .menu-item + .menu-item {
  border-top: 1px solid rgba(171, 171, 171, 0.23);
}
@media screen and (min-width: 37.5625rem) {
  .mobileMenu__menu .menu-item a {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0.9375rem;
  }
}
.mobileMenu__footer {
  margin-top: 1.5625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookies {
  position: fixed;
  bottom: 2.125rem;
  right: 2.125rem;
  width: 31.25rem;
  z-index: 5;
  border-radius: 0.3125rem;
  background-color: #fff;
  padding: 2.375rem 3.125rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.29);
  opacity: 0;
  visibility: hidden;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
}
.cookies__title {
  color: #d33535;
  margin-bottom: 1.25rem;
}
.cookies__text {
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding-bottom: 1.5rem;
}
.cookies__more {
  margin-left: 1.25rem;
  background-color: #cfcfcf;
}
@media screen and (max-width: 37.5rem) {
  .cookies {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
  }
  .cookies .btn, .cookies .contactPage__mapLink, .cookies .footer__newsletterSubmit, .cookies .sliderItem__button {
    display: block;
  }
  .cookies__more {
    margin-left: 0;
    margin-top: 1.25rem;
  }
}
.cookies--active {
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}

.generalContact {
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.generalContact__title {
  font-size: 1.125rem;
  color: #2d2a2a;
  margin-bottom: 1.125rem;
}
.generalContact__address {
  color: #ed1c24;
  font-size: 1.125rem;
  text-decoration: none;
}

.contactSelector {
  margin-top: 2.1875rem;
}
.contactSelector__label {
  font-size: 1.125rem;
  color: #2d2a2a;
  margin-bottom: 0.6875rem;
  display: block;
}
.contactSelector__list {
  margin-top: 1.5rem;
  position: relative;
  height: 4.0625rem;
}
.contactSelector__item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
}
.contactSelector__item--active {
  opacity: 1;
  visibility: visible;
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 0ms;
}
.contactSelector__email, .contactSelector__phone {
  font-size: 1.125rem;
  color: #ed1c24;
  display: block;
  text-decoration: none;
}
.contactSelector__email + .contactSelector__email, .contactSelector__email + .contactSelector__phone, .contactSelector__phone + .contactSelector__email, .contactSelector__phone + .contactSelector__phone {
  margin-top: 0.75rem;
}

.field {
  margin-top: 0.9375rem;
}
.field textarea {
  height: 8.125rem;
  resize: none;
}
.field__label {
  color: #2d2a2a;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.3125rem;
  display: block;
}
.field__input {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.375rem;
  padding: 0.875rem 0.9375rem;
  -webkit-appearance: none;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background-color: #f7f7f7;
  font-family: "Hind", sans-serif;
  color: #2d2a2a;
  outline: none;
}
.field__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9d9c9c;
  opacity: 1;
  /* Firefox */
}
.field__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9d9c9c;
}
.field__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9d9c9c;
}
.field--required {
  position: relative;
}
.field--required:after {
  content: "*";
  position: absolute;
  right: 1.25rem;
  top: 1.8125rem;
  font-size: 1rem;
  margin-top: -0.5em;
  color: #9d9c9c;
}
.field--textearea .field__input {
  height: 8.125rem;
}
.field--select {
  position: relative;
}
.field--select .field__input {
  color: #9d9c9c;
  padding-right: 2.5rem;
}
.field--select .field__input:focus {
  color: #2d2a2a;
}
.field--select .field__inputWrapper {
  position: relative;
}
.field--select .field__inputWrapper:after {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  margin-top: -0.5em;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.375rem;
  color: #a8a8a8;
}

.contactPage__inside {
  display: flex;
  padding: 6.25rem 12.5%;
}
.contactPage__infos {
  width: 27.7777777778%;
  margin-right: 5.5555555556%;
  color: #2d2a2a;
  line-height: 1.125rem;
}
.contactPage__address, .contactPage__companyName, .contactPage__contact {
  font-size: 1rem;
  line-height: 1.125rem;
}
.contactPage .socialMenu {
  margin-top: 1.375rem;
}
.contactPage__contact, .contactPage__address {
  margin-top: 1.375rem;
  display: block;
}
.contactPage__address, .contactPage__contact a {
  text-decoration: none;
  color: #2d2a2a;
}
.contactPage__address:hover, .contactPage__address:focus, .contactPage__contact a:hover, .contactPage__contact a:focus {
  text-decoration: underline;
}
.contactPage__title {
  font-size: 1rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: #e43535;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
}
.contactPage__form {
  width: 66.6666666667%;
  padding-left: 11.1111111111%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 62.5em) {
  .contactPage__inside {
    flex-direction: column;
    padding: 3.125rem 8.3333333333%;
  }
  .contactPage__infos {
    width: 100%;
    margin-bottom: 3.125rem;
  }
  .contactPage__form {
    width: 100%;
    padding-left: 0;
    border-left: none;
  }
}
.contactPage__selector {
  display: flex;
  width: 100%;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 2.5rem;
}
.contactPage__selectorItem {
  position: relative;
  width: 50%;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  color: rgba(211, 53, 53, 0.84);
  padding: 1rem;
  transition: 200ms color cubic-bezier(0.25, 0.46, 0.45, 0.94), 200ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.contactPage__selectorItem + .contactPage__selectorItem {
  border-left: 1px solid #a8a8a8;
}
.contactPage__selectorItem:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.5625rem;
  color: #e43535;
  position: absolute;
  left: 25px;
  top: 50%;
  margin-top: -0.5em;
  opacity: 0;
  transform: translateX(-10px);
  transition: 200ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 200ms transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (max-width: 37.5rem) {
  .contactPage__selectorItem:before {
    display: none;
  }
}
.contactPage__selectorItem:focus, .contactPage__selectorItem:hover, .contactPage__selectorItem--active {
  background-color: #363333;
  color: #fff;
}
.contactPage__selectorItem:focus:before, .contactPage__selectorItem:hover:before, .contactPage__selectorItem--active:before {
  opacity: 1;
  transform: none;
}
.contactPage__formContainer {
  min-height: 40.625rem;
  position: relative;
}
.contactPage__formItem {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 400ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94), 0ms visibility linear 200ms;
}
.contactPage__formItem--active {
  visibility: visible;
  opacity: 1;
  transition: 400ms opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms, 0ms visibility linear 0ms;
}
.contactPage__submit {
  margin-top: 1.25rem;
}
.contactPage hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  margin: 1.125rem 0;
}
.contactPage .message {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  font-weight: 500;
}
.contactPage .message.error {
  color: #e43535;
}
.contactPage .message.notice {
  color: green;
}
.contactPage .errors {
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: right;
  letter-spacing: 0.05em;
  color: #e43535;
  margin-top: 0.625rem;
  font-weight: 500;
}
.contactPage__map {
  position: relative;
  min-height: 28.75rem;
  background-color: #ebe9e4;
}
.contactPage__mapObject {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.contactPage__mapFooter {
  height: 8.125rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 42, 42, 0.88);
}
.contactPage__mapLink {
  background-color: transparent;
  border: 1px solid #e43535;
}
.contactPage__mapLink:hover, .contactPage__mapLink:focus {
  background-color: #e43535;
}

.field {
  margin-top: 0.9375rem;
}
.field textarea {
  height: 8.125rem;
  resize: none;
}
.field__label {
  color: #2d2a2a;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.3125rem;
  display: block;
}
.field__input {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.375rem;
  padding: 0.875rem 0.9375rem;
  -webkit-appearance: none;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background-color: #f7f7f7;
  font-family: "Hind", sans-serif;
  color: #2d2a2a;
  outline: none;
}
.field__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9d9c9c;
  opacity: 1;
  /* Firefox */
}
.field__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9d9c9c;
}
.field__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9d9c9c;
}
.field--required {
  position: relative;
}
.field--required:after {
  content: "*";
  position: absolute;
  right: 1.25rem;
  top: 1.8125rem;
  font-size: 1rem;
  margin-top: -0.5em;
  color: #9d9c9c;
}
.field--textearea .field__input {
  height: 8.125rem;
}
.field--select {
  position: relative;
}
.field--select .field__input {
  color: #9d9c9c;
  padding-right: 2.5rem;
}
.field--select .field__input:focus {
  color: #2d2a2a;
}
.field--select .field__inputWrapper {
  position: relative;
}
.field--select .field__inputWrapper:after {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  margin-top: -0.5em;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 0.375rem;
  color: #a8a8a8;
}

.postContent {
  max-width: 44.375rem;
  margin: 7.5rem auto 7.5rem auto;
}
.postContent--article {
  margin-top: 4.6875rem;
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 50rem) {
  .postContent {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 8.3333333333%;
    margin-right: 8.3333333333%;
    width: inherit .postContent --article;
    width-margin-top: 3.75rem;
    width-margin-bottom: 5rem;
  }
}

.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 130px );
}
.errorPage__center {
  max-width: 44.375rem;
}
.errorPage__title {
  font-size: 3.75rem;
  font-weight: 700;
}
.errorPage__backHome {
  margin-top: 2.5rem;
}
@media screen and (max-width: 71.625em) {
  .errorPage {
    height: calc( 100vh - 100px );
  }
}