.homeAbout{
    margin-top: s(125);
    margin-bottom: s(175);

    &__inside{
        padding: 0 col(2,24);
        display: flex;
        align-items: center;
    }

    &__image{
        width: col(6,20);
        margin-right: col(4,20);
        box-shadow: s(14) s(14) 0 color(shadowGrey);

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content{
        width: col(10,20);
    }

    &__title{
        @extend %bigTitle;
    }

    &__text{
        @extend %body;
        margin-bottom: s(24);
    }

    @media screen and (max-width: em(map-get($default-grid, width) + 40 + 40, 16)) {
        margin-top: s(70);
        margin-bottom: s(90);

        &__inside{
            padding: 0 col(1,24);

        }
    }

    @media screen and (max-width: s(600, 16)) {
        &__inside{
            flex-direction: column;
            padding: 0 col(2,24);
        }

        &__image{
            width: col(20,20);
            margin-right: 0;
        }

        &__content{
            width: col(20,20);

        }
    }

}