.banner{
    height: s(340);
    position: relative;
    background-color: #211f1f;

    &__image{
        @include cover();
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:after{
            content: "";
            @include cover();
            background-image: linear-gradient(to top, rgba(black, 0.71), transparent);
        
        }
    }

    &__content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        text-align: center;
    }

    &__subtitle{
        font-size: s(14);
        letter-spacing: ps_ls(50);
        font-weight: 600;
        color: color(lightRed);
        text-transform: uppercase;
    }

    &__title{
        font-size: s(30);
        line-height: s(40);
        font-weight: 700;
        color: color(white);
        text-shadow: 0 0 3px rgba(0,0,0,0.19);
    }

    &__menu{
        position: sticky;
        top: 0;
        z-index: 10;
        left: 0;
        right: 0;
        margin-top: s(-42);
        background-color: rgba(#ed1c24, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__menuItem{
        padding: s(15) s(24);
        font-size: s(12);
        letter-spacing: pl_ls(50);
        color: color(white);
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;

        &--immo{
            background-color: rgba(#952e31, 0.8);
        }
    }

    &--category{
        .banner__content{
            @extend .wrapper;
            position: absolute;
            left: calc(50% - 489px);
            bottom: s(63);
            top: inherit;
            transform: none;
        }
    }

    &--thinner{
        height: s(150);
    }
    @media screen and (max-width: em(1000,16)){
        height: s(280);

        &__content{
            width: 80%;

            .banner--category &{
                left: col(1,24);
                position: absolute;
                bottom: s(40);
                top: inherit;
                max-width: inherit;
                width: inherit;
            }
        }
    }

    @media screen and (max-width: em(600,16)){
        height: s(220);

        &__title{
            font-size: s(22);
            line-height: s(32);
            br{
                display: none;
            }
        }
        
        &__menu{
            display: none;
        }
    }
}