// - @include hidden()
// **********************************************************
// Hides an element but keeps it usable by Screen-readers
// **********************************************************
@mixin hidden(){
    position: absolute;
    top: -99999px;
    left: -99999px;
}

// - @include cover()
// **********************************************************
// sets an element in an absolute position, covering its
// relative parent.
// **********************************************************
@mixin cover(){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

// - @include clearfix([both|left|right])
// **********************************************************
// Default : both
// Reset float on parent-element of floated elements
// **********************************************************
@mixin clearfix( $w:both ){
    &:after{
        content: "";
        display: block;
        clear: $w;
    }
}

// - @include clickableTransparentBg()
// **********************************************************
// Adds a transparent background to a clickable block-
// element. Why ? Because IE.
// **********************************************************
@mixin clickableTransparentBg() {
    background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7") 0 0 repeat;
}

// Similar as .textSection
// @todo globalize ?
@mixin sectionTitle {
  position: relative;

  &:after {
    // pink separation
    @include icon(titleLine);
    color: color(pink);
    display: block;
    font-size: 4px;
    margin: s(24, 4) 0 s(30, 4);

    @include mappy-query(b-medium) {
      margin: s(30, 4) 0 s(50, 4);
    }
  }
}

// Pseudo element
// Create an empty css pseudo element. Reusable for, e.g., icons
@mixin el($width: 100%, $height: 20px, $display: block) {
  content: '';
  display: block;
  height: $height;
  width: $width;
}

// Buttons
// Unstyle button
@mixin button-unstyled($border: none, $background: transparent) {
  appearance: none;
  background: $background;
  border: $border;
  box-shadow: none;
}
