.slider{
    height: 690/1366*100vw;
    height: 67vh;

    position: relative;
    overflow: hidden;
    background-color: rgba(0,0,0,1);

    @media screen and (max-width: em(1000, 16)) {
        height: 690/1366*100vw;
        
    }

    @media screen and (max-width: em(600, 16)) {
        height: s(330);
    }
}

.sliderItem{
    @include cover();

    .js & {

        opacity: 0;
        visibility: hidden;
        transition: 600ms opacity linear , 0ms visibility linear 800ms;
        .sliderItem__image{
            transform-origin: center bottom;
            transform: scale(1.035);
            transition: 600ms transform easing(ease-out-cubic);
        }

        &--active{
            opacity: 1;
            visibility: visible;
            transition: 600ms opacity linear,  0ms visibility linear 0ms;    

            .sliderItem__image{
                transform: scale(1.0);
            }
        }
    }


    &__image{
        @include cover();
        &:after{
            content: '';
            @include cover();
            background-color: rgba(#1a1b1b, 0.2);
            background-image: linear-gradient(to bottom, rgba(#000, 0.17), rgba(255,255,255,0), rgba(#000, 0.49));
        }
        img{
            @include cover();
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .wrapper{
        height: 100%;
    }
    &__inside{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 col(1,24) s(124) col(1,24);
    }

    &__subtitle{
        text-transform: uppercase;
        font-weight: 600;
        font-size: s(12);
        letter-spacing: ps_ls(50);
        color: color(white);
    }

    &__title{
        font-weight: 700;
        font-size: s(36);
        line-height: s(40);
        color: color(white);
        padding-top: s(21);
        margin-top: s(21);
        margin-bottom: s(27);
        position: relative;

        &:before{
            content: '';
            width: s(37);
            height: s(2);
            background-color: color(lightRed);
            position: absolute;
            top: s(-1);
            left: 0;
        }
    }

    &__button{
        @extend .btn;
    }

    @media screen and (max-width: em(1000, 16)) {
        &__inside{
            padding-left: col(1,24);
            padding-right: col(1,24);
            padding-bottom: s(40);
        }

        

        &__title{
            font-size: s(28);
            margin-top: s(18);
            margin-bottom: s(18);
        }
    }

    @media screen and (max-width: em(600, 16)) {

        &__inside{
            text-align: center;
            padding-bottom: s(80);
        }
        &__subtitle{
            font-size: s(9);
        }

        &__title{
            font-size: s(22);
            line-height: s(28);
            margin-top: s(12);
            padding-bottom: s(12);
            margin-bottom: s(15);
            &:before{
                width: s(20);
                height: s(2);
                left: 50%;
                margin-left: s(-10);
            }
        }
    }
}

.sliderBullet{
    position: absolute;
    bottom: s(124);
    right: calc(50vw - 978px/2);
    display: flex;
    z-index: 3;

    @media screen and (max-width: em(1024, 16)) {
        right: s(80);
    }

    @media screen and (max-width: em(1000, 16)) {
        right: s(40);
        bottom: s(40);
    }

    @media screen and (max-width: em(600, 16)) {
        bottom: s(50);
        right: 50%;
        transform: translate3d(50%,0,0) scale(0.5);
    }

    
    &__item{
        text-indent: -9999px;
        display: block;
        width: 20px;
        height: 20px;
        position: relative;

        & + &{
            margin-left: 8px;
        }
    }
    &__svg {
        @include cover();
        transform-origin: center;
        mix-blend-mode: difference;
    }
    &__fix {
        fill: none;
        stroke: rgba(#434a52, 0.3);
        stroke-width: 2;
    }
    &__animated {
        fill: none;
        stroke: color(white);
        stroke-width: 2;
        stroke-dasharray: 0, 57;
        stroke-linecap: round;
        opacity: 0;
        transition: stroke-dasharray 100ms easing(ease-out-quad), opacity 100ms easing(ease-out-quad)
    }

    &__active {
        fill: none;
        stroke: color(white);
        stroke-width: 2;
        opacity: 0;
        transition: opacity 100ms easing(ease-out-quad)
    }
  
    &__item--animated &__animated{
        opacity: 1;
        stroke-dasharray: 57, 57;
        transition: stroke-dasharray 4000ms easing(ease-out-quad), opacity 10ms easing(ease-out-quad)
    }

    &__item--active &__active{
        opacity: 1;
    }
  
  
  
}