%visually-hidden{
    position: absolute !important;
    clip: rect(1px,1px,1px,1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
}
.btn{
    text-decoration: none;
    font-weight: 600;
    font-size: s(12);
    letter-spacing: ps_ls(50);
    text-transform: uppercase;
    background-color: #d33535;
    padding: s(14) s(18);
    border-radius: s(5);
    color: color(white);
    transition: 200ms background-color easing(ease-out-quad);
    display: inline-block;
    border: none;
    font-family: 'Hind', sans-serif;

    
    &:hover,
    &:focus{
        background-color: darken(#d33535, 10%);
    }

    &--immo{
        position: relative;
        padding-left: s(50+20);
        overflow: hidden;
        &:before{
            content: ''; 
            position: absolute;
            left: 0;
            width: s(50);
            top: 0;
            bottom: 0;
            background-color: #a92a2a;
        }

        &:after{
            @include icon(immo);
            position: absolute;
            left: 0;
            width: s(50);
            top: 50%;
            margin-top: -0.5em;
            text-align: center;
            font-size: s(28);
        }
    }

    @media screen and (max-width: em(600, 16)) {
        font-size: s(10);
    }
}

%title{
    text-transform: uppercase;
    color: color(footer);
    font-size: s(20);
    line-height: s(30);


}

%subtitle{
    padding-left: s(54);
    font-size: s(14);
    letter-spacing: ps_ls(50);
    color: color(lightRed);
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    margin-bottom: s(42);

    &:before{
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: s(2);
        width: s(37);
        margin-top: s(-2);
        background-color: color(lightRed);
    }
}

%intro{
    font-size: s(20);
    line-height: s(26);
    margin-bottom: s(34);
    color: color(footer);
}

%body{
    color: color(footer);
    font-size: s(16);
    line-height: s(24);
    p + p{
        margin-top: s(24);
    }
    b, strong{
        font-weight: 700;;
    }
    a{
        color: #ed1c24;
    }
    ul, ol{
        margin: s(33) 0;
        counter-reset: list;

    }

    li{
        position: relative;
        display: block;
        padding-left: s(33);
 
    }

    li + li{
        margin-top: s(14);
    }

    ul{
        li:before{
            @include icon(aRight);
            position: absolute;
            left: 0;
            font-size: s(9);
            top: s(6.5);
            color: #e43535;
        }
    }
    ol{
        li:before{
            counter-increment: list;
            content: counter(list) ". ";
            color: #ed1c24;
            position: absolute;
            left: 0;
        }
    }

    h1{
        font-size: s(30);
        line-height: s(40);
        margin-bottom: s(30);
        margin-top: s(30);
    }
    h2{
        @extend %title;
        margin-bottom: s(30);
        margin-top: s(30);
    }

    h3{
        font-size: s(14);
        line-height: s(18);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: ps_ls(50);
        color: #ed1c24;
        margin-bottom: s(30);
        margin-top: s(30);
    }

    figure{
        margin: s(24) 0;
        img{
            width: 100%;
            height: auto;
        }
    }

    blockquote{

        text-align: center;
        font-size: s(18);
        line-height: s(24);
        text-align: center;
        color: #7d7b7b;
        max-width: s(545);
        margin: s(42) auto;

        &:before{
            content:'\00AB';
        }

        &:after{
            content:'\00BB';
        }


    }

    cite{
        text-align: center;
        color: #7d7b7b;
        margin-top: s(-42+15);
        margin-bottom: s(42);
        text-transform: uppercase;
        font-weight: 600;
        font-size: s(12);
        line-height: s(16);
        display: block;
        letter-spacing: ps_ls(50);
    }
}

%bigTitle{
    color: color(footer);
    font-size: s(30);
    line-height: s(40);
    position: relative;
    padding-top: s(30);
    margin-bottom: s(34);

    strong{
        font-weight: 700;
    }

    &:before{
        content:'';
        width: s(37);
        height: s(2);
        background-color: color(lightRed);
        position: absolute;
        top: 0;
        left: 0;
    }

    @media screen and (max-width: em(600, 16)) {
        font-size: s(25);
        line-height: s(35);
        margin-bottom: s(25);
        padding-top: 0;
        &:before{
            display: none;
        }
    }
}