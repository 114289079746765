.aboutVideo{
    position: relative;
    background-color: #f4f4f4;
    padding-bottom: s(100);
    position: relative;
    &:before{
        content:'';
        position: absolute;
        top: -1px;
        height: s(131);
        left: 0;
        right: 0;
        background-color: color(white);
    }
    

    &__sizer{
        margin: 0 auto;
        background-color: color(black);
        @include respVideoContainer(14, 24);
    }
    
    &__playButton{
        @include cover();
        z-index: 1;
        background-color: color(black);
        text-decoration: none;
        opacity: 1;
        visibility: visible;
        transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 0ms;

        &:before{
            z-index: 2;
            @include icon(play);
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: -0.5em;
            font-size: s(59);
            text-align: center;
            color: white;
            transition: 200ms color easing(ease-out-quad);
        }

        &:hover,
        &:focus{
            &:before{
                color: color(lightRed);
            }

            .aboutVideo__previewImage img{
                //opacity: 0.42;
            }
        }

        &--unactive{
            opacity: 0;
            visibility: hidden;
            transition: 200ms opacity easing(ease-out-quad), 0ms visibility linear 200ms;
        }
    }

    &__previewImage{
        @include cover();

        img{
            transition: 200ms opacity easing(ease-out-quad);
            @include cover();
            opacity: 0.32;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__player{
        @include cover();
    }

    @media screen and (max-width: s(800, 16)){
        &__sizer{
            @include respVideoContainer(20, 24);
        }
    }

    @media screen and (max-width: s(600, 16)){
        &__sizer{
            @include respVideoContainer(22, 24);
        }
    }
}