.cookies{
    position: fixed;
    bottom: s(34);
    right: s(34);
    width: s(500);
    z-index: 5;
    border-radius: s(5);
    background-color: color(white);
    padding: s(38) s(50);
    box-shadow: 0 0 s(10) rgba(color(black), 0.29);

    opacity: 0;
    visibility: hidden;
    transition: 200ms opacity easing(ease-out-quad),0ms visibility linear 200ms;

    &__title{
        @extend %title;
        color: color(red);
        margin-bottom: s(20);
    }

    &__text{
        font-size: s(14);
        line-height: s(24);
        padding-bottom: s(24);
    }

    &__more{
        margin-left: s(20);
        background-color: #cfcfcf;
    }

    @media screen and (max-width: s(600, 16)){
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0;
        width: 100%;

        .btn{
            display: block;
        }

        &__more{
            margin-left: 0;
            margin-top: s(20);
        }
    }

    &--active{
        opacity: 1;
        visibility: visible;
        transition: 200ms opacity easing(ease-out-quad),
                    0ms visibility linear 0ms;
    }
}