.cardProject{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    position: relative;
    width: 33.3333%;

    &__text{
        padding: s(29);
        color: color(white);
        text-align: center;
        transition: 200ms background-color easing(ease-out-quad);
    }

    &__title{
        font-weight: 600;
        font-size: s(14);
        line-height: s(18);
        margin-bottom: s(6);
        text-transform: uppercase;
    }

    &__category{
        font-size: s(14);
        line-height: s(18);
    }

    &__image{
        position: relative;
        img{
            width: 100%;
            height: auto;
            display: block;
        }

        &:after{
            content:'';
            background-color: rgba(#211f1f, 0.68);  
            @include cover();
            opacity: 0;
            transition: 200ms opacity easing(ease-out-quad);
        }
    }

    &__link{
        @include cover();
        z-index: 2;

        &:hover,
        &:focus{

            .cardProject__linkLabel{
                opacity: 1;
            }

            & + .cardProject__text{
                
            }
            & + .cardProject__text + .cardProject__image{
                &:after{
                    opacity: 1;
                }
            }
        }
    }

    &__linkLabel{
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        color: color(white);
        top: s(250/2);
        transform: translateY(-50%);
        font-size: s(12);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: ps_ls(50);
        opacity: 0;
        transition: 200ms opacity easing(ease-out-quad);

        &:before{
            @include icon(plus);
            display: block;
            font-size: s(16);
            margin-bottom: s(20);
        }
    }
    &--projectList{
        //height: s(280);
        .cardProject__title{
            margin-bottom: s(4);
        }
        .cardProject__text{
            background-color: rgba(#393839, 0.9);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            padding: s(20);

        }



        .cardProject__link{
            &:hover,
            &:focus{
                & + .cardProject__text{
                    background-color: #c44040;
                }
            }
        }

        .cardProject__linkLabel{
            font-size: s(10);
            top: s((250-65)/2);

            &:before{
                margin-bottom: s(8);
            }
        }
    }

    &--featuredProject{
        width: 33.3333%;
        height: 100%;

        .cardProject__link{
            &:hover,
            &:focus{
                & + .cardProject__text{
                    background-color: #c44040;
                }
            }
        }

        .cardProject__image{
            img{
                height: s(250);
                object-fit: cover;
            }
        }

        .cardProject__text{
            background-color: color(lightRed);
        }

        @media screen and (max-width: em(600,16)){
            width: 100%;
            height: s(350)
        }
    }
    
}